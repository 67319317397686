import { Game } from './game';
import { AssetLoader } from './assetLoader';
import './styles.css';

// Add font
const fontLink = document.createElement('link');
fontLink.href = 'https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap';
fontLink.rel = 'stylesheet';
document.head.appendChild(fontLink);

// Setup iOS audio unlock (must be done at the document level)
function setupIOSAudioUnlock() {
    // Add an event listener for the first user interaction
    const unlockAudio = () => {
        // Create and immediately play a silent audio element
        const silentAudio = document.createElement('audio');
        silentAudio.setAttribute('src', 'data:audio/mp3;base64,SUQzBAAAAAABEUdFT0JqZWN0AABPYQ=='); // Tiny valid MP3
        silentAudio.setAttribute('playsinline', 'true');
        silentAudio.setAttribute('preload', 'auto');
        silentAudio.play().catch(e => {
            console.log('Silent audio play failed:', e);
        });
        
        // Remove event listeners once played
        document.removeEventListener('touchstart', unlockAudio);
        document.removeEventListener('touchend', unlockAudio);
        document.removeEventListener('click', unlockAudio);
        
        // Also dispatch the resumeAudio event
        document.dispatchEvent(new CustomEvent('resumeAudio'));
    };
    
    document.addEventListener('touchstart', unlockAudio);
    document.addEventListener('touchend', unlockAudio);
    document.addEventListener('click', unlockAudio);
}

// Call the setup function
setupIOSAudioUnlock();

async function main() {
    try {
        // Create loading indicator
        const loadingIndicator = document.createElement('div');
        loadingIndicator.className = 'loading-indicator';
        
        const spinner = document.createElement('div');
        spinner.className = 'loading-spinner';
        loadingIndicator.appendChild(spinner);
        
        const loadingText = document.createElement('div');
        loadingText.textContent = 'Loading game assets...';
        loadingIndicator.appendChild(loadingText);
        
        document.body.appendChild(loadingIndicator);

        // Initialize asset loader
        const assetLoader = new AssetLoader();
        await assetLoader.init();

        // Remove loading indicator
        loadingIndicator.remove();

        // Initialize game
        new Game(assetLoader);
    } catch (error) {
        console.error('Failed to initialize game:', error);
        
        // Show error message
        const errorMessage = document.createElement('div');
        errorMessage.className = 'error-message';
        errorMessage.textContent = 'Failed to load game. Please refresh the page.';
        document.body.appendChild(errorMessage);
    }
}

main(); 