import * as THREE from 'three';
import { BoardRenderer, BoardTile } from './BoardRenderer';

export interface InputCallback {
  (row: number, col: number, cellIndex: number): void;
}

export class InputManager {
  private boardRenderer: BoardRenderer;
  private mouse: THREE.Vector2;
  private raycaster: THREE.Raycaster;
  private enabled: boolean = true;
  private clickCallback: InputCallback | null = null;
  private hoverCallback: ((tile: BoardTile | null) => void) | null = null;
  private lastHoveredTile: BoardTile | null = null;

  constructor(boardRenderer: BoardRenderer) {
    this.boardRenderer = boardRenderer;
    this.mouse = new THREE.Vector2();
    this.raycaster = new THREE.Raycaster();
    
    // Add event listeners
    document.addEventListener('mousemove', this.onMouseMove.bind(this));
    document.addEventListener('click', this.onClick.bind(this));
    document.addEventListener('touchstart', this.onTouchStart.bind(this) as EventListener);
    document.addEventListener('touchmove', this.onTouchMove.bind(this) as EventListener, { passive: false } as AddEventListenerOptions);
  }

  public setClickCallback(callback: InputCallback): void {
    this.clickCallback = callback;
  }

  public setHoverCallback(callback: (tile: BoardTile | null) => void): void {
    this.hoverCallback = callback;
  }

  public enable(): void {
    this.enabled = true;
  }

  public disable(): void {
    this.enabled = false;
    // Clear hover state if necessary (adjust material access if not basic)
    if (this.lastHoveredTile && this.lastHoveredTile.material instanceof THREE.MeshBasicMaterial) {
      // Note: This opacity change might not work well with MeshToonMaterial
      // Consider alternative hover effects (e.g., slight color change, outline highlight)
      this.lastHoveredTile.material.opacity = 1; // Assuming 1 is the default
    }
    this.lastHoveredTile = null;
  }

  public isEnabled(): boolean {
    return this.enabled;
  }

  private onMouseMove(event: MouseEvent): void {
    if (!this.enabled) return;
    
    // Update mouse position
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    
    this.handleHover();
  }

  private onClick(event: MouseEvent): void {
    if (!this.enabled || !this.clickCallback) return;
    
    // Get mouse position
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    
    this.handleClick();
  }

  private onTouchStart(event: TouchEvent): void {
    if (!this.enabled || !this.clickCallback) return;
    
    // Prevent default to avoid scrolling/zooming
    event.preventDefault();
    
    if (event.touches.length > 0) {
      const touch = event.touches[0];
      this.updateTouchPosition(touch);
      this.handleClick();
    }
  }

  private onTouchMove(event: TouchEvent): void {
    if (!this.enabled) return;
    
    // Prevent default to avoid scrolling/zooming
    event.preventDefault();
    
    if (event.touches.length > 0) {
      const touch = event.touches[0];
      this.updateTouchPosition(touch);
      this.handleHover();
    }
  }

  private updateTouchPosition(touch: Touch): void {
    const rect = this.boardRenderer['renderer']?.domElement.getBoundingClientRect();
    if (!rect) return;
    
    this.mouse.x = ((touch.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((touch.clientY - rect.top) / rect.height) * 2 + 1;
  }

  private handleClick(): void {
    if (!this.enabled || !this.clickCallback) return;
    console.log('[InputManager] handleClick triggered'); // Log entry
    
    // Update raycaster with mouse position
    this.raycaster = this.boardRenderer.getCameraRaycaster(this.mouse);
    
    // Check for intersections with the TILES
    const tiles = this.boardRenderer.getClickableTiles();
    console.log(`[InputManager] Found ${tiles.length} clickable tiles.`); // Log tile count
    
    const intersects = this.raycaster.intersectObjects(tiles);
    console.log(`[InputManager] Raycaster intersections: ${intersects.length}`); // Log intersection count
    
    if (intersects.length > 0) {
      // Get the first intersected tile
      const tile = intersects[0].object as BoardTile;
      // Get data from the TILE's userData
      const { row, col, isBlocked } = tile.userData;
      console.log(`[InputManager] Intersected Tile: row=${row}, col=${col}, isBlocked=${isBlocked}`); // Log tile data
      
      // Only trigger callback if the TILE itself is not blocked.
      // The Game logic will determine if the space is occupied.
      if (!isBlocked) {
        console.log('[InputManager] Tile is not blocked, invoking callback...'); // Log callback condition met
        // Calculate index based on board size (game state might be more reliable?)
        const boardSize = this.boardRenderer.getBoardSize(); 
        const cellIndex = row * boardSize + col;
        this.clickCallback(row, col, cellIndex);
      } else {
        console.log('[InputManager] Tile is blocked, callback NOT invoked.'); // Log callback condition failed
      }
    } else {
         console.log('[InputManager] No intersections found.'); // Log no intersections
    }
  }

  private handleHover(): void {
    if (!this.enabled) return;
    
    // Update raycaster with mouse position
    this.raycaster = this.boardRenderer.getCameraRaycaster(this.mouse);
    
    // Check for intersections with TILES
    const tiles = this.boardRenderer.getClickableTiles();
    const intersects = this.raycaster.intersectObjects(tiles);
    
    // Reset effect for previously hovered tile
    if (this.lastHoveredTile && this.lastHoveredTile.material instanceof THREE.MeshBasicMaterial) {
        // Reset opacity to the default value used in BoardRenderer (0.5)
        this.lastHoveredTile.material.opacity = 0.5; // Corrected reset value (was 1)
    }
    this.lastHoveredTile = null; // Clear last hovered initially
    
    if (intersects.length > 0) {
        const tile = intersects[0].object as BoardTile;
        const { isBlocked } = tile.userData;
        
        // Only show hover effect if tile is not blocked
        if (!isBlocked) {
            if (tile.material instanceof THREE.MeshBasicMaterial) {
                 // Apply hover effect (e.g., opacity)
                 tile.material.opacity = 0.7; // Example hover effect
            }
            // TODO: Consider a hover effect compatible with MeshToonMaterial if needed
            // e.g., slightly change color, or temporarily add tile to OutlinePass selectedObjects?

            this.lastHoveredTile = tile;
            if (this.hoverCallback) {
                 this.hoverCallback(tile);
            }
            return; // Exit after finding the first valid hover target
        }
    }

    // If no valid hover target was found
    if (this.hoverCallback) {
        this.hoverCallback(null);
    }
  }

  public dispose(): void {
    // Remove event listeners
    document.removeEventListener('mousemove', this.onMouseMove.bind(this));
    document.removeEventListener('click', this.onClick.bind(this));
    document.removeEventListener('touchstart', this.onTouchStart.bind(this) as EventListener);
    document.removeEventListener('touchmove', this.onTouchMove.bind(this) as EventListener);
    
    // Clear references
    this.clickCallback = null;
    this.hoverCallback = null;
    this.lastHoveredTile = null;
  }

  // Make this public so Game can call it after board resize
  public setupEventListeners(): void {
    // Clear existing listeners first to avoid duplicates
    if (this.boardContainer) {
      // ... existing code ...
    }
  }
} 