import { authService } from './services/authService';

export class Menu {
    private container: HTMLDivElement;
    private title: HTMLDivElement;
    private buttonsContainer: HTMLDivElement;
    private playAIButton: HTMLButtonElement;
    private playLocalButton: HTMLButtonElement;
    private playRankedButton: HTMLButtonElement;
    private username: string | null = null;
    private userId: string | null = null;
    private userInfoContainer: HTMLDivElement | null = null;
    private statsContainer: HTMLElement | null = null;
    private statsRefreshInterval: number | null = null;

    constructor() {
        this.container = document.createElement('div');
        this.container.className = 'game-menu';

        // Create title using SVG instead of text
        this.title = document.createElement('div');
        this.title.className = 'game-title';
        
        // Load the SVG as an object
        const svgObject = document.createElement('object');
        svgObject.type = 'image/svg+xml';
        svgObject.data = 'assets/logo.svg';
        svgObject.className = 'title-svg';
        
        // Apply appropriate styling to the SVG container
        svgObject.style.width = '100%';
        svgObject.style.maxWidth = '600px';
        svgObject.style.height = 'auto';
        
        // Add fallback in case SVG fails to load
        svgObject.onerror = () => {
            console.error("Failed to load SVG title");
            // Fallback to text title
            const textTitle = document.createElement('h1');
            textTitle.textContent = "TIC TAC TITANS";
            textTitle.className = "game-title-text";
            this.title.appendChild(textTitle);
        };
        
        // Add the SVG to the title container
        this.title.appendChild(svgObject);
        this.container.appendChild(this.title);

        // Check if user is logged in and display user info
        this.checkAuthStatus();

        // Create buttons container
        this.buttonsContainer = document.createElement('div');
        this.buttonsContainer.className = 'menu-buttons';

        // Create Play vs AI button
        this.playAIButton = document.createElement('button');
        this.playAIButton.className = 'menu-button ai-button';
        this.playAIButton.textContent = 'Play vs AI';
        this.playAIButton.onclick = async () => {
            try {
                // Resume any suspended AudioContext (for mobile)
                await this.resumeAudioContext();
                
                const event = new CustomEvent('startGame', { 
                    bubbles: true, 
                    cancelable: true,
                    detail: { mode: 'ai' } 
                });
                this.container.dispatchEvent(event);
                this.hide();
            } catch (error) {
                console.error('Error starting AI game:', error);
            }
        };

        // Create Play Local button
        this.playLocalButton = document.createElement('button');
        this.playLocalButton.className = 'menu-button local-button';
        this.playLocalButton.textContent = 'Play Local';
        this.playLocalButton.onclick = async () => {
            try {
                // Resume any suspended AudioContext (for mobile)
                await this.resumeAudioContext();
                
                const event = new CustomEvent('startGame', { 
                    bubbles: true, 
                    cancelable: true,
                    detail: { mode: 'local' } 
                });
                this.container.dispatchEvent(event);
                this.hide();
            } catch (error) {
                console.error('Error starting local game:', error);
            }
        };

        // Create Play Ranked button
        this.playRankedButton = document.createElement('button');
        this.playRankedButton.className = 'menu-button online-button';
        this.playRankedButton.textContent = 'Play Ranked';
        this.playRankedButton.onclick = async () => {
            try {
                await this.resumeAudioContext();
                
                // Check if user is already logged in
                if (authService.isLoggedIn()) {
                    const user = authService.getUser();
                    if (user) {
                        this.username = user.username;
                        this.userId = user.user_id;
                        this.startRankedGame();
                        return;
                    }
                }
                
                // Import the AuthDialog only when needed (dynamic import)
                import('./components/AuthDialog').then(({ AuthDialog }) => {
                    const authDialog = new AuthDialog(
                        // Success callback
                        (username, userId) => {
                            this.username = username;
                            this.userId = userId;
                            this.updateUserInfo();
                            this.startRankedGame();
                        },
                        // Cancel callback
                        () => {
                            console.log('Authentication cancelled by user');
                        }
                    );
                    authDialog.show();
                }).catch(error => {
                    console.error('Error loading auth dialog:', error);
                });
            } catch (error) {
                console.error('Error starting ranked game:', error);
            }
        };

        // Add buttons to container
        this.buttonsContainer.appendChild(this.playAIButton);
        this.buttonsContainer.appendChild(this.playLocalButton);
        this.buttonsContainer.appendChild(this.playRankedButton);
        this.container.appendChild(this.buttonsContainer);

        // Add menu to document
        document.body.appendChild(this.container);
    }
    
    private async checkAuthStatus(): Promise<void> {
        // Check if user is already logged in
        if (authService.isLoggedIn()) {
            const user = authService.getUser();
            if (user) {
                this.username = user.username;
                this.userId = user.user_id;
                this.updateUserInfo();
            }
        }
    }
    
    private updateUserInfo(): void {
        // Remove existing user info if any
        if (this.userInfoContainer && this.userInfoContainer.parentNode) {
            this.userInfoContainer.parentNode.removeChild(this.userInfoContainer);
        }
        
        // Create user info container only if user is logged in
        if (this.username) {
            this.userInfoContainer = document.createElement('div');
            this.userInfoContainer.className = 'user-info';
            
            const userIcon = document.createElement('span');
            userIcon.className = 'user-info-icon user-icon';
            
            const usernameDisplay = document.createElement('div');
            usernameDisplay.className = 'username-display';
            usernameDisplay.textContent = this.username;
            
            const logoutButton = document.createElement('button');
            logoutButton.className = 'logout-button logout-icon-button';
            logoutButton.title = 'Logout';
            logoutButton.onclick = () => {
                authService.logout();
                this.username = null;
                this.userId = null;
                this.updateUserInfo();
                this.clearStatsDisplay();
            };

            const logoutIcon = document.createElement('span');
            logoutIcon.className = 'user-info-icon logout-icon';
            logoutButton.appendChild(logoutIcon);
            
            this.userInfoContainer.appendChild(userIcon);
            this.userInfoContainer.appendChild(usernameDisplay);
            this.userInfoContainer.appendChild(logoutButton);
            
            this.container.appendChild(this.userInfoContainer);
        }
    }

    private clearStatsDisplay(): void {
        const statsDisplay = document.getElementById('menu-stats-display');
        if (statsDisplay) {
            statsDisplay.innerHTML = '';
        }
    }

    private startRankedGame(): void {
        if (!this.username || !this.userId) {
            console.error('Cannot start ranked game without authentication');
            return;
        }
        
        const event = new CustomEvent('startGame', { 
            bubbles: true, 
            cancelable: true,
            detail: { 
                mode: 'ranked',
                username: this.username,
                userId: this.userId
            } 
        });
        this.container.dispatchEvent(event);
        this.hide();
    }

    // Helper to resume audio context - addresses mobile browser autoplay policy
    private async resumeAudioContext(): Promise<void> {
        console.log('Attempting to resume audio context from menu interaction');
        
        // Create and play a silent sound to unlock audio on Android Chrome
        try {
            const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
            
            // Play a silent sound
            const buffer = audioContext.createBuffer(1, 1, 22050);
            const source = audioContext.createBufferSource();
            source.buffer = buffer;
            source.connect(audioContext.destination);
            source.start(0);
            
            console.log('Silent sound played to unlock audio');
            
            // Explicitly resume the context
            if (audioContext.state === 'suspended') {
                await audioContext.resume();
                console.log('Menu: AudioContext resumed successfully');
            }
            
            // Dispatch an event that AssetLoader can listen for
            document.dispatchEvent(new CustomEvent('resumeAudio'));
            
            // Wait a short moment to ensure audio context is fully ready
            await new Promise(resolve => setTimeout(resolve, 100));
            
        } catch (e) {
            console.warn('Menu: Error handling audio context:', e);
        }
    }

    private createStatsContainer(): void {
        console.log("Attempting to create stats container...");
        const existingStats = document.getElementById('menu-stats-display');
        if (existingStats) {
            console.log("Stats container already exists.");
            this.statsContainer = existingStats;
            return;
        }

        this.statsContainer = document.createElement('div');
        this.statsContainer.id = 'menu-stats-display';
        this.statsContainer.className = 'menu-stats';
        
        // --- Start Modification: Append directly to body ---
        console.log("Appending stats container to document body.");
        document.body.appendChild(this.statsContainer);
        // --- End Modification ---
    }

    private async fetchAndDisplayStats(): Promise<void> {
        // Ensure container exists
        if (!this.statsContainer) {
            console.log("fetchAndDisplayStats: Stats container not found, attempting to create.");
            this.createStatsContainer(); // Attempt to recreate
            if (!this.statsContainer) {
                console.error("fetchAndDisplayStats: Failed to create stats container.");
                return; // Still failed, exit
            }
        }
        
        console.log("Fetching and displaying stats..."); // Log fetch attempt
        try {
            // Placeholder data until backend is ready
            const stats = {
                active_games: Math.floor(Math.random() * 10), // Replace with real data
                active_players: Math.floor(Math.random() * 20) // Replace with real data
            };
            
            this.statsContainer.innerHTML = `
                <span>Active Games: ${stats.active_games}</span>
                <span> | </span>
                <span>Online Players: ${stats.active_players}</span>
            `;
            console.log("Stats displayed:", this.statsContainer.innerHTML.trim()); // Log success
        } catch (error) {
            console.error("Error fetching menu stats:", error);
            this.statsContainer.textContent = 'Stats unavailable';
        }
    }

    private startStatsRefresh(): void {
        this.stopStatsRefresh(); // Clear existing interval
        this.fetchAndDisplayStats(); // Fetch immediately
        this.statsRefreshInterval = window.setInterval(() => {
            this.fetchAndDisplayStats();
        }, 30000); // Refresh every 30 seconds
    }

    private stopStatsRefresh(): void {
        if (this.statsRefreshInterval !== null) {
            clearInterval(this.statsRefreshInterval);
            this.statsRefreshInterval = null;
        }
    }

    show() {
        console.log("Menu show() called."); // Log show call
        // Hide any existing turn indicator when showing the menu
        const turnIndicator = document.getElementById('info');
        if (turnIndicator) {
            turnIndicator.style.display = 'none';
        }
        
        // Dispatch a menu-show event so Game can hide its elements
        const menuShowEvent = new CustomEvent('menu-show', {
            bubbles: true,
            cancelable: true
        });
        document.dispatchEvent(menuShowEvent);
        
        this.checkAuthStatus();
        
        // Create stats container if it doesn't exist and start refreshing
        this.createStatsContainer();
        this.startStatsRefresh();
        
        requestAnimationFrame(() => {
            this.container.style.display = 'flex';
            this.container.style.visibility = 'visible';
            this.container.style.opacity = '1';
        });
    }

    hide() {
        this.container.style.opacity = '0';
        this.container.style.visibility = 'hidden';
        setTimeout(() => {
            this.container.style.display = 'none';
        }, 300);
        
        // Stop refreshing stats when menu hides
        this.stopStatsRefresh();
    }

    dispose() {
        if (this.container && this.container.parentNode) {
            this.container.parentNode.removeChild(this.container);
        }
        
        // Also remove stats container on dispose
        if (this.statsContainer && this.statsContainer.parentNode) {
            this.statsContainer.parentNode.removeChild(this.statsContainer);
        }
        
        // Stop refreshing stats on dispose
        this.stopStatsRefresh();
    }
}