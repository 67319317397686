import { WebSocketManager } from './WebSocketManager';
import { GameState } from '../gameState';
import { UIManager } from './UIManager';
import { AssetLoader } from '../assetLoader';
import { authService } from '../services/authService';

export interface MatchmakingOptions {
  username: string;
  onMatchFound: (gameId: string, opponent: string) => void;
  onError: (message: string) => void;
  onReconnect?: (gameId: string, opponent: string) => void;
}

export interface GameOptions {
  gameId: string;
  username: string;
  onGameUpdate: (board: (string | null)[], currentPlayer: string) => void;
  onPlayerInfo: (playerSymbol: 'X' | 'O', isPlayerTurn: boolean) => void;
  onMoveMade: (cellIndex: number, symbol: string, board?: (string | null)[]) => void;
  onBoardExpand: (boardSize: number, board: (string | null)[], currentPlayer: string) => void;
  onGameWin: (winnerSymbol: string, winningLine: {row: number, col: number}[]) => void;
  onOpponentDisconnect: () => void;
  onConnectionLost: () => void;
}

export class MultiplayerManager {
  private matchmakingWs: WebSocketManager | null = null;
  private gameWs: WebSocketManager | null = null;
  private uiManager: UIManager;
  private assetLoader: AssetLoader;
  private username: string | null = null;
  private opponent: string | null = null;
  private currentGameId: string | null = null;
  private playerSymbol: 'X' | 'O' | null = null;
  private isPlayerTurn: boolean = false;
  private isGameOver: boolean = false;
  private matchmakingOptions: MatchmakingOptions | null = null;
  private gameOptions: GameOptions | null = null;
  private apiBaseUrl: string = 'https://api.anklebite.games';
  private wsBaseUrl: string = 'wss://api.anklebite.games';

  constructor(uiManager: UIManager, assetLoader: AssetLoader) {
    this.uiManager = uiManager;
    this.assetLoader = assetLoader;
  }

  public startMatchmaking(options: MatchmakingOptions): void {
    console.log("Starting matchmaking for user:", options.username);
    
    // Store options for reconnection if needed
    this.matchmakingOptions = options;
    this.username = options.username;
    
    // Clean up any existing connections
    this.cleanupWebSockets();
    this.isGameOver = false;
    
    // Check for auth info
    if (!this.username) {
      console.error('Cannot start matchmaking without a username');
      options.onError('Cannot start matchmaking: Please log in first');
      return;
    }
    
    // Show waiting screen while finding an opponent
    this.uiManager.showWaitingScreen();
    this.uiManager.addCancelButton(() => this.cancelMatchmaking());
    
    // Get authentication token
    const token = authService.getToken();
    if (!token) {
      console.error('No authentication token available');
      this.uiManager.hideWaitingScreen();
      options.onError('Authentication required');
      return;
    }
    
    // Set up matchmaking WebSocket
    const wsUrl = `${this.wsBaseUrl}/ws/matchmaking/${this.username}`;
    this.matchmakingWs = new WebSocketManager({
      url: wsUrl,
      token: token,
      pingInterval: 30000,
      onOpen: () => {
        console.log('Matchmaking WebSocket connection established');
        
        // Send initial message with user ID if available
        const user = authService.getUser();
        if (user && this.matchmakingWs) {
          this.matchmakingWs.send({
            type: "connect",
            user_id: user.user_id,
            username: user.username
          });
        }
      },
      onMessage: (data) => this.handleMatchmakingMessage(data),
      onClose: (event) => {
        console.log("Matchmaking WebSocket connection closed:", event.code, event.reason);
        
        // Only show reconnection message if we haven't found a match yet
        if (!this.currentGameId && !this.isGameOver) {
          this.uiManager.hideWaitingScreen();
          options.onError("Lost connection to matchmaking service. Please try again.");
        }
      },
      onError: (error) => {
        console.error("Matchmaking WebSocket error:", error);
        this.uiManager.hideWaitingScreen();
        options.onError("Failed to connect to matchmaking service. Please try again.");
      }
    });
    
    // Connect to matchmaking
    this.matchmakingWs.connect().catch(error => {
      console.error("Failed to connect to matchmaking:", error);
      this.uiManager.hideWaitingScreen();
      options.onError("Failed to connect to matchmaking service. Please try again.");
    });
  }

  private handleMatchmakingMessage(data: any): void {
    if (!this.matchmakingOptions) return;
    
    try {
      console.log("Matchmaking update:", data);
      
      // Handle matchmaking status updates
      if (data.type === "matchmaking_status") {
        if (data.status === "waiting") {
          console.log("Successfully joined matchmaking pool");
          this.uiManager.updateWaitingScreen("Searching for opponent...");
        }
        return;
      }
      
      // Handle player already in game
      if (data.status === "already_in_game") {
        console.log("Already in an active game - reconnecting");
        this.opponent = data.opponent;
        
        // Show a message to the user
        this.uiManager.updateWaitingScreen("Reconnecting to existing game...");
        
        // Call the reconnect callback
        if (this.matchmakingOptions.onReconnect) {
          this.matchmakingOptions.onReconnect(data.game_id, data.opponent);
        } else {
          // Fallback to regular match found
          this.matchmakingOptions.onMatchFound(data.game_id, data.opponent);
        }
        return;
      }
      
      // Check for the correct message type from the backend
      if (data.type === "match_found") {
        // Match found
        this.opponent = data.opponent;
        this.currentGameId = data.game_id;
        
        // Close matchmaking connection now that we've found a match
        this.closeMatchmakingConnection();
        
        // Notify caller
        this.matchmakingOptions.onMatchFound(data.game_id, data.opponent);
      } else if (data.type === "error" || data.status === "error") {
        console.error("Matchmaking error:", data.message);
        this.uiManager.hideWaitingScreen();
        this.matchmakingOptions.onError(data.message);
      }
    } catch (error) {
      console.error("Error processing matchmaking message:", error);
    }
  }

  public joinGame(options: GameOptions): void {
    console.log(`Joining game ${options.gameId} as ${options.username}`);
    
    // Store options for the game
    this.gameOptions = options;
    this.username = options.username;
    this.currentGameId = options.gameId;
    this.isGameOver = false;
    
    // Close any existing game connection
    if (this.gameWs) {
      this.gameWs.disconnect();
      this.gameWs = null;
    }
    
    // Add a small delay before connecting to give the server time to set up
    setTimeout(() => {
      this.setupGameWebSocket();
    }, 1500);
  }

  private setupGameWebSocket(): void {
    if (!this.gameOptions || !this.currentGameId) {
      console.error("Cannot set up game WebSocket: missing game ID or options");
      return;
    }
    
    console.log(`Setting up game WebSocket for game ID: ${this.currentGameId}`);
    
    // Get authentication token
    const token = authService.getToken();
    if (!token) {
      console.error('No authentication token available');
      if (this.gameOptions.onConnectionLost) {
        this.gameOptions.onConnectionLost();
      }
      return;
    }
    
    // Skip connection if the game is over
    if (this.isGameOver) {
      console.log("Game is over, not connecting to WebSocket");
      return;
    }
    
    // Set up WebSocket connection
    const wsUrl = `${this.wsBaseUrl}/ws/games/${this.currentGameId}`;
    this.gameWs = new WebSocketManager({
      url: wsUrl,
      token: token,
      pingInterval: 30000,
      onOpen: () => {
        console.log("Game WebSocket connection established");
        
        // Send player info to join the game
        if (this.username && this.gameWs) {
          this.gameWs.send({
            type: "join",
            player_id: this.username
          });
          console.log(`Sent join request with username: ${this.username}`);
        }
      },
      onClose: (event) => {
        console.log(`Game WebSocket connection closed: code=${event.code}, reason=${event.reason}`);
        
        // Handle different close scenarios
        if (this.isGameOver) {
          console.log("Game is already over, not reconnecting");
        } else if (event.code === 1000) {
          // Normal closure - server initiated close
          console.log("Server closed the connection normally");
        } else if (this.gameOptions?.onConnectionLost) {
          // Abnormal closure, report to caller
          this.gameOptions.onConnectionLost();
        }
      },
      onError: (error) => {
        console.error("Game WebSocket error:", error);
        if (this.gameOptions?.onConnectionLost) {
          this.gameOptions.onConnectionLost();
        }
      }
    });
    
    // Set up message handlers
    this.setupGameMessageHandlers();
    
    // Connect to game
    this.gameWs.connect().catch(error => {
      console.error("Failed to connect to game:", error);
      if (this.gameOptions?.onConnectionLost) {
        this.gameOptions.onConnectionLost();
      }
    });
  }

  private setupGameMessageHandlers(): void {
    if (!this.gameWs || !this.gameOptions) return;
    
    // Player info handler
    this.gameWs.registerMessageHandler("player_info", (data) => {
      console.log("Received player info from server:", data);
      
      // Store the player's symbol
      if (data.player_symbol) {
        this.playerSymbol = data.player_symbol;
        console.log(`Player symbol assigned from server: ${this.playerSymbol}`);
      }
      
      // Determine turn state from server data
      if (data.current_player && this.playerSymbol) {
        this.isPlayerTurn = (this.playerSymbol === data.current_player);
      } else if (data.is_player_turn !== undefined) {
        // Some messages include explicit is_player_turn flag
        this.isPlayerTurn = data.is_player_turn;
      }
      
      // Notify caller
      this.gameOptions.onPlayerInfo(this.playerSymbol as 'X' | 'O', this.isPlayerTurn);
    });
    
    // Game update handler
    this.gameWs.registerMessageHandler("game_update", (data) => {
      console.log("Received game state update:", data);
      
      // Update the player turn state
      if (data.current_player && this.playerSymbol) {
        this.isPlayerTurn = (this.playerSymbol === data.current_player);
      }
      
      // Notify caller
      if (data.board && data.current_player) {
        this.gameOptions.onGameUpdate(data.board, data.current_player);
      }
    });
    
    // Game state handler (alternative format some servers use)
    this.gameWs.registerMessageHandler("game_state", (data) => {
      console.log("Received game state update:", data);
      
      // Update the player turn state
      if (data.current_player && this.playerSymbol) {
        this.isPlayerTurn = (this.playerSymbol === data.current_player);
      }
      
      // Notify caller
      if (data.board && data.current_player) {
        this.gameOptions.onGameUpdate(data.board, data.current_player);
      }
    });
    
    // Move made handler
    this.gameWs.registerMessageHandler("move_made", (data) => {
      console.log("Received move notification:", data);
      
      // Play sound effect for moves
      this.assetLoader.playSoundEffect('place');
      
      // Update turn state based on current_player from server
      if (data.current_player && this.playerSymbol) {
        this.isPlayerTurn = (this.playerSymbol === data.current_player);
      }
      
      // Notify caller with appropriate data
      if (data.board) {
        // Full board update
        this.gameOptions.onMoveMade(data.cell_index, data.symbol, data.board);
      } else if (data.cell_index !== undefined && data.symbol) {
        // Just the move
        this.gameOptions.onMoveMade(data.cell_index, data.symbol);
      }
    });
    
    // Board expansion handler
    this.gameWs.registerMessageHandler("board_expand", (data) => {
      this.handleBoardExpansion(data);
    });
    
    // Alternative board expansion format
    this.gameWs.registerMessageHandler("board_expanded", (data) => {
      this.handleBoardExpansion(data);
    });
    
    // Game win handler
    this.gameWs.registerMessageHandler("game_win", (data) => {
      console.log("Received game win notification:", data);
      
      // Determine if this client is the winner
      const isWinner = (data.winner_symbol === this.playerSymbol);
      
      // Play appropriate sound effect
      if (isWinner) {
        this.assetLoader.playSoundEffect('win');
      }
      
      // Mark game as over
      this.isGameOver = true;
      
      // Get winning line information
      const winningLine = data.winning_line || [];
      
      // Notify caller
      this.gameOptions.onGameWin(data.winner_symbol, winningLine);
    });
    
    // Player disconnect handler
    this.gameWs.registerMessageHandler("player_disconnected", (data) => {
      console.log("Player disconnected:", data);
      
      // Only act if it's the opponent who disconnected
      if (data.player_id && data.player_id !== this.username) {
        // Notify caller
        this.gameOptions.onOpponentDisconnect();
      }
    });

    // Handle board expansion message
    this.gameWs.registerMessageHandler('board_expand', (data) => {
      if (this.gameOptions?.onBoardExpand) {
        // Pass currentPlayer along
        this.gameOptions.onBoardExpand(data.board_size, data.board, data.current_player);
      }
    });
  }

  private handleBoardExpansion(data: any): void {
    if (!this.gameOptions) return;
    
    console.log("Board expansion detected:", data);
    
    // Extract required data with fallbacks
    const boardSize = data.board_size || data.new_size || 6;
    const expandedBoard = data.board || [];
    
    if (expandedBoard.length > 0) {
      console.log(`Expanding board to ${boardSize}x${boardSize}`);
      
      // Play expansion sound effect
      this.assetLoader.playSoundEffect('expand');
      
      // Update turn state based on current_player from server
      if (data.current_player && this.playerSymbol) {
        this.isPlayerTurn = (this.playerSymbol === data.current_player);
      } else {
        // If server doesn't specify current player, X always goes first after expansion
        this.isPlayerTurn = this.playerSymbol === 'X';
      }
      
      // Notify caller
      this.gameOptions.onBoardExpand(boardSize, expandedBoard, data.current_player);
    }
  }

  public makeMove(cellIndex: number): boolean {
    if (!this.gameWs || !this.currentGameId || !this.isPlayerTurn) {
      console.log(`Cannot make move: ws=${!!this.gameWs}, gameId=${!!this.currentGameId}, playerTurn=${this.isPlayerTurn}`);
      return false;
    }
    
    console.log(`Sending move at cell index ${cellIndex}`);
    
    const moveMessage = {
      type: "move",
      cell_index: cellIndex,
      timestamp: Date.now()
    };
    
    // Send the move
    const success = this.gameWs.send(moveMessage);
    
    if (success) {
      // Temporarily disable input while waiting for server response
      this.isPlayerTurn = false;
    } else {
      console.error('Failed to send move');
    }
    
    return success;
  }

  public cancelMatchmaking(): void {
    console.log("Canceling matchmaking");
    this.closeMatchmakingConnection();
    this.uiManager.hideWaitingScreen();
  }

  private closeMatchmakingConnection(): void {
    if (this.matchmakingWs) {
      // Send leave matchmaking message before closing
      if (this.username) {
        this.matchmakingWs.disconnect("leave_matchmaking", {
          username: this.username,
          timestamp: Date.now()
        });
      } else {
        this.matchmakingWs.disconnect();
      }
      this.matchmakingWs = null;
    }
  }

  public endGame(): void {
    console.log("Ending game");
    this.isGameOver = true;
    
    // Clean up WebSocket connections
    this.cleanupWebSockets();
    
    // Reset game state
    this.currentGameId = null;
    this.playerSymbol = null;
    this.opponent = null;
  }

  private cleanupWebSockets(): void {
    // Close matchmaking connection if active
    this.closeMatchmakingConnection();
    
    // Close game connection if active
    if (this.gameWs) {
      // Send a proper disconnect notification if we have a game ID
      if (this.currentGameId && this.username) {
        this.gameWs.disconnect("player_disconnect", {
          player_id: this.username,
          timestamp: Date.now()
        });
      } else {
        this.gameWs.disconnect();
      }
      this.gameWs = null;
    }
  }

  public dispose(): void {
    console.log("Disposing multiplayer manager");
    
    // Clean up all connections
    this.cleanupWebSockets();
    
    // Clear state
    this.matchmakingOptions = null;
    this.gameOptions = null;
    this.currentGameId = null;
    this.username = null;
    this.opponent = null;
    this.playerSymbol = null;
    this.isPlayerTurn = false;
    this.isGameOver = false;
  }
} 