export enum GameMode {
  LOCAL = 'local',
  AI = 'ai',
  RANKED = 'ranked'
}

export enum GamePhase {
  MENU = 'menu',
  PLAYER_TURN = 'player_turn',
  AI_TURN = 'ai_turn',
  GAME_OVER = 'game_over'
}

export interface GameStartEvent {
  mode: GameMode;
  username?: string;
}

export interface WinningLine {
  row: number;
  col: number;
}

export interface PlayerInfo {
  username: string;
  symbol?: 'X' | 'O';
}

export interface GameResult {
  winner: PlayerInfo | null;
  isDraw: boolean;
  message: string;
  winningLine?: WinningLine[];
} 