export interface MatchPlayersInfo {
  player1: string;
  player2: string;
}

export class UIManager {
  private turnIndicator: HTMLDivElement | null = null;
  private waitingScreen: HTMLDivElement | null = null;
  private matchSummary: HTMLDivElement | null = null;
  private gameOverContainer: HTMLDivElement | null = null;
  private errorMessage: HTMLDivElement | null = null;

  constructor() {
    this.initializeUI();
  }

  private initializeUI(): void {
    // Initialize the turn indicator
    let infoElement = document.getElementById('info');
    if (infoElement) {
      this.turnIndicator = infoElement as HTMLDivElement;
    } else {
      // Create if it doesn't exist
      this.turnIndicator = document.createElement('div');
      this.turnIndicator.id = 'info';
      this.turnIndicator.style.cssText = `
        position: fixed;
        bottom: 20px;
        left: 0;
        width: 100%;
        text-align: center;
        color: #33ff66;
        font-size: 24px;
        font-weight: bold;
        text-shadow: 0 0 10px rgba(51, 255, 102, 0.5);
        z-index: 99999;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 10px 0;
        pointer-events: none;
        transform: translateZ(0);
        will-change: transform;
      `;
      document.body.appendChild(this.turnIndicator);
    }
    
    // Hide by default
    this.turnIndicator.style.display = 'none';
  }

  public updateTurnIndicator(message: string, color: string = '#33ff66'): void {
    if (!this.turnIndicator) return;
    
    this.turnIndicator.innerHTML = message;
    this.turnIndicator.style.color = color;
    this.turnIndicator.style.display = 'block';
    this.turnIndicator.style.visibility = 'visible';
  }

  public updatePlayerTurn(isPlayerTurn: boolean, playerSymbol: string | null = null): void {
    if (!this.turnIndicator) return;
    
    // Make sure the turn indicator is visible
    this.turnIndicator.style.display = 'block';
    
    // Clear any existing content
    this.turnIndicator.innerHTML = '';
    
    // Show player symbol if available
    if (playerSymbol) {
      const playerInfo = document.createElement('div');
      playerInfo.style.fontSize = '22px';
      playerInfo.style.marginBottom = '5px';
      playerInfo.style.color = '#ffffff';
      playerInfo.textContent = `You are playing as ${playerSymbol}`;
      this.turnIndicator.appendChild(playerInfo);
    }
    
    // Add turn status
    const turnInfo = document.createElement('div');
    turnInfo.style.fontSize = '26px';
    turnInfo.style.fontWeight = 'bold';
    turnInfo.textContent = isPlayerTurn ? "YOUR TURN" : "OPPONENT'S TURN";
    turnInfo.style.color = isPlayerTurn ? '#00ff00' : '#ff6666';
    this.turnIndicator.appendChild(turnInfo);
  }

  public showWaitingScreen(): void {
    // Remove any existing waiting screen first
    this.hideWaitingScreen();
    
    this.waitingScreen = document.createElement('div');
    this.waitingScreen.className = 'waiting-screen';
    this.waitingScreen.innerHTML = `
      <h2>Finding Opponent...</h2>
      <div class="spinner"></div>
    `;
    document.body.appendChild(this.waitingScreen);
  }

  public updateWaitingScreen(message: string): void {
    if (!this.waitingScreen) {
      this.showWaitingScreen();
    }
    
    if (this.waitingScreen) {
      this.waitingScreen.innerHTML = `
        <h2>${message}</h2>
        <div class="spinner"></div>
      `;
    }
  }

  public addCancelButton(onCancel: () => void): void {
    if (!this.waitingScreen) return;
    
    // First, remove any existing cancel buttons to prevent duplicates
    const existingButtons = this.waitingScreen.querySelectorAll('.cancel-button');
    existingButtons.forEach(button => button.remove());
    
    // Then add a new cancel button
    const cancelButton = document.createElement('button');
    cancelButton.textContent = 'Cancel';
    cancelButton.className = 'cancel-button';
    cancelButton.onclick = onCancel;
    this.waitingScreen.appendChild(cancelButton);
  }

  public hideWaitingScreen(): void {
    if (this.waitingScreen && this.waitingScreen.parentNode) {
      this.waitingScreen.parentNode.removeChild(this.waitingScreen);
      this.waitingScreen = null;
    }
  }

  public showMatchSummary(playersInfo: MatchPlayersInfo): void {
    // Remove any existing match summary
    this.hideMatchSummary();
    
    this.matchSummary = document.createElement('div');
    this.matchSummary.className = 'match-summary';
    this.matchSummary.innerHTML = `
      <div class="player">${playersInfo.player1}</div>
      <div class="vs">VS</div>
      <div class="player">${playersInfo.player2}</div>
    `;
    document.body.appendChild(this.matchSummary);
  }

  public hideMatchSummary(): void {
    if (this.matchSummary && this.matchSummary.parentNode) {
      this.matchSummary.parentNode.removeChild(this.matchSummary);
      this.matchSummary = null;
    }
  }

  public showGameOver(message: string, isWinner: boolean, callbacks: {
    playAgain?: () => void;
    playRankedAgain?: () => void;
    backToMenu?: () => void;
  }): void {
    // Hide turn indicator
    this.hideTurnIndicator();
    
    // Remove any previous game over screen first
    this.hideGameOver(); 

    // Create the main container mimicking game-menu
    this.gameOverContainer = document.createElement('div');
    // Add base menu class and specific post-game class
    this.gameOverContainer.className = 'game-menu post-game-menu'; 
    this.gameOverContainer.hidden = false; // Ensure it's visible

    // Add result message
    const messageDiv = document.createElement('div');
    messageDiv.className = 'post-game-message'; // New class for styling
    messageDiv.textContent = message;
    // Apply win/loss/draw color (keep dynamic style)
    messageDiv.style.color = message.toLowerCase().includes('win') ? '#00ff00' : 
                             message.toLowerCase().includes('draw') ? '#ffff00' : '#ff3333';
    this.gameOverContainer.appendChild(messageDiv);
    
    if (callbacks) {
      // Create button container
      const buttonContainer = document.createElement('div');
      // Use menu-buttons class for layout consistency
      buttonContainer.className = 'menu-buttons post-game-buttons'; 
      
      if (callbacks.playAgain) {
        // Create Play Again button
        const playAgainButton = document.createElement('button');
        // Use base menu-button class + specific class
        playAgainButton.className = 'menu-button play-again-button'; 
        playAgainButton.textContent = 'Play Again';
        playAgainButton.onclick = callbacks.playAgain;
        buttonContainer.appendChild(playAgainButton);
      }
      
      if (callbacks.playRankedAgain) {
        // Create Play Ranked Again button
        const playRankedButton = document.createElement('button');
        // Use base menu-button class + specific class
        playRankedButton.className = 'menu-button play-ranked-again-button';
        playRankedButton.textContent = 'Play Ranked Again';
        playRankedButton.onclick = callbacks.playRankedAgain;
        buttonContainer.appendChild(playRankedButton);
      }
      
      if (callbacks.backToMenu) {
        // Create Back to Menu button
        const menuButton = document.createElement('button');
        // Use base menu-button class + specific class
        menuButton.className = 'menu-button post-game-menu-button'; 
        menuButton.textContent = 'Back to Menu';
        menuButton.onclick = callbacks.backToMenu;
        buttonContainer.appendChild(menuButton);
      }
      
      // Add buttons to container
      this.gameOverContainer.appendChild(buttonContainer);
    }
    
    // Add to DOM - Append to body for consistent positioning like main menu
    document.body.appendChild(this.gameOverContainer); 
    // Show with fade-in effect (optional, can be added via CSS transition)
    requestAnimationFrame(() => {
      if (this.gameOverContainer) {
        this.gameOverContainer.style.opacity = '1';
        this.gameOverContainer.style.visibility = 'visible';
        this.gameOverContainer.style.pointerEvents = 'auto';
      }
    });
  }

  public hideGameOver(): void {
    // Fade out effect (optional)
    if (this.gameOverContainer) {
      this.gameOverContainer.style.opacity = '0';
      this.gameOverContainer.style.visibility = 'hidden';
      this.gameOverContainer.style.pointerEvents = 'none';

      // Remove after transition or delay
      // Use transitionend event listener or setTimeout
      setTimeout(() => {
        if (this.gameOverContainer && this.gameOverContainer.parentNode) {
          this.gameOverContainer.parentNode.removeChild(this.gameOverContainer);
          this.gameOverContainer = null;
        }
      }, 300); // Match typical fade-out duration
    }
  }

  public showErrorMessage(message: string, duration: number = 3000): void {
    // Remove any existing error message
    this.hideErrorMessage();
    
    this.errorMessage = document.createElement('div');
    this.errorMessage.className = 'error-message';
    this.errorMessage.textContent = message;
    document.body.appendChild(this.errorMessage);
    
    // Auto-remove after specified duration
    setTimeout(() => {
      this.hideErrorMessage();
    }, duration);
  }

  public hideErrorMessage(): void {
    if (this.errorMessage && this.errorMessage.parentNode) {
      this.errorMessage.parentNode.removeChild(this.errorMessage);
      this.errorMessage = null;
    }
  }

  public hideTurnIndicator(): void {
    if (this.turnIndicator) {
      this.turnIndicator.style.display = 'none';
    }
  }

  public clearAllUI(): void {
    this.hideTurnIndicator();
    this.hideWaitingScreen();
    this.hideMatchSummary();
    this.hideGameOver();
    this.hideErrorMessage();
  }

  public dispose(): void {
    this.clearAllUI();
    
    // Remove turn indicator from DOM
    if (this.turnIndicator && this.turnIndicator.parentNode) {
      this.turnIndicator.parentNode.removeChild(this.turnIndicator);
      this.turnIndicator = null;
    }
  }
} 