import { v4 as uuidv4 } from 'uuid';

export enum GamePhase {
  MENU = 'menu',
  PLAYER_TURN = 'player_turn',
  AI_TURN = 'ai_turn',
  GAME_OVER = 'game_over'
}

export interface GameStateData {
  board: (string | null)[];
  currentPlayer: 'X' | 'O';
  gamePhase: 'playing' | 'ended' | 'expanding';
  isLocalGame: boolean;
  winner?: string | null;
  isDraw?: boolean;
  boardSize: number;
  isExpanding: boolean;
  isExpanded: boolean;
  cells: (string | null)[];
  lastMove: number | null;
  timestamp?: number; // Add timestamp for tracking state updates
}

export default class GameStateManager {
  private apiUrl: string = 'https://api.anklebite.games';
  private wsUrl: string = 'wss://api.anklebite.games';
  private gameId: string = '';
  private wsConnection: WebSocket | null = null;
  private subscribers: ((state: GameStateData) => void)[] = [];
  private currentState: GameStateData;
  private isAIGame: boolean = false;
  private localStateOnly: boolean = false;
  private stateLock: boolean = false;
  private boardSize: number = 3;
  private originalBoardSize: number = 3;
  private expandedBoardSize: number = 6; // Change from 5 to 6
  private playerId: string = '';

  constructor() {
    console.log("Initializing GameStateManager");
    
    try {
      // Generate a unique player ID if none exists
      this.playerId = localStorage.getItem('playerId') || uuidv4();
      localStorage.setItem('playerId', this.playerId);
      
      console.log("Using FastAPI backend at", this.apiUrl);
    } catch (e) {
      console.error("Failed to initialize backend connection:", e);
    }
    
    // Initialize with clean state
    this.currentState = this.getCleanState();
  }

  private getCleanState(): GameStateData {
    const size = this.boardSize;
    const cells = Array(size * size).fill(null);
    
    return {
      board: cells.slice(),
      currentPlayer: 'X',
      gamePhase: 'playing',
      isLocalGame: true,
      boardSize: size,
      isExpanding: false,
      isExpanded: false,
      cells: cells.slice(),
      lastMove: null
    };
  }

  public initializeState(initialState: Partial<GameStateData>): string {
    // Merge initial state with default state
    const newState = {
      ...this.getCleanState(),
      ...initialState,
      timestamp: Date.now()
    };
    
    this.currentState = newState as GameStateData;
    this.isAIGame = initialState.isLocalGame || false;
    this.localStateOnly = initialState.isLocalGame || false;
    
    if (!this.localStateOnly) {
      // For multiplayer, create a game on the backend
      this.gameId = this.gameId || uuidv4();
      
      this.createOrJoinGame(this.gameId)
        .then(() => {
          // Connect to WebSocket for real-time updates
          this.setupStateSync();
          console.log("Multiplayer game state initialized");
        })
        .catch(error => {
          console.error("Failed to initialize game state:", error);
          // Fallback to local state
          this.localStateOnly = true;
        });
    } else {
      console.log("Local game initialized");
    }
    
    this.notifySubscribers();
    return this.gameId;
  }

  private async createOrJoinGame(gameId: string): Promise<void> {
    try {
      const response = await fetch(`${this.apiUrl}/api/games/${gameId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      
      if (response.status === 404) {
        // Game doesn't exist, create it
        const createResponse = await fetch(`${this.apiUrl}/api/games`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            player1_id: this.playerId
          })
        });
        
        if (!createResponse.ok) {
          throw new Error("Failed to create game");
        }
        
        const game = await createResponse.json();
        this.gameId = game.id;
      } else if (response.ok) {
        // Game exists, join it
        const game = await response.json();
        this.gameId = game.id;
        
        // Update local state with server state
        this.currentState = {
          ...this.currentState,
          board: game.board,
          currentPlayer: game.current_player,
          gamePhase: game.phase,
          winner: game.winner_id,
          isDraw: game.is_draw
        };
      } else {
        throw new Error("Failed to check game existence");
      }
    } catch (error) {
      console.error("Error in createOrJoinGame:", error);
      throw error;
    }
  }

  public updateState(newState: Partial<GameStateData>): void {
    if (this.stateLock) {
      console.warn("State update blocked: state is locked");
      return;
    }
    
    // Add timestamp to track state updates
    newState.timestamp = Date.now();
    
    // Merge the new state with the current state
    this.currentState = {
      ...this.currentState,
      ...newState
    };
    
    if (!this.localStateOnly) {
      // For multiplayer, update through FastAPI in the background
      fetch(`${this.apiUrl}/api/games/${this.gameId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          game_id: this.gameId,
          board: this.currentState.board,
          current_player: this.currentState.currentPlayer,
          phase: this.currentState.gamePhase,
          winner_id: this.currentState.winner,
          is_draw: this.currentState.isDraw
        })
      })
      .then(response => {
        if (!response.ok) {
          console.error("Failed to update state on server");
        }
      })
      .catch(error => {
        console.error("Error updating state:", error);
      });
    }
    
    this.notifySubscribers();
  }

  public getCurrentPlayer(): 'X' | 'O' {
    return this.currentState.currentPlayer;
  }

  public getState(): GameStateData {
    return { ...this.currentState };
  }

  public subscribe(callback: (state: GameStateData) => void): void {
    this.subscribers.push(callback);
    
    // Immediately notify with current state
    callback(this.getState());
  }

  public joinGame(gameId: string): void {
    this.gameId = gameId;
    console.log("Setting up state synchronization for game:", gameId);
    
    this.createOrJoinGame(gameId)
      .then(() => {
        // Connect to WebSocket for real-time updates
        this.setupStateSync();
        console.log("Joined existing game");
        this.notifySubscribers();
      })
      .catch(error => {
        console.error("Failed to join game:", error);
      });
  }

  public resetGame(): void {
    const cleanState = this.getCleanState();
    this.updateState({
      ...cleanState,
      boardSize: this.boardSize,
      isExpanded: this.boardSize > this.originalBoardSize
    });
  }

  public canMakeMove(cellIndex: number): boolean {
    // Check if the cell is already occupied
    if (this.currentState.cells[cellIndex] !== null) {
      return false;
    }
    
    // Check if it's the right phase for making moves
    if (
      this.currentState.gamePhase === 'ended' || 
      this.currentState.isExpanding || 
      (this.isAIGame && this.currentState.currentPlayer === 'O')
    ) {
      return false;
    }
    
    return true;
  }

  public forceClearCell(cellIndex: number): void {
    if (cellIndex < 0 || cellIndex >= this.currentState.cells.length) {
      return;
    }
    
    // Create a copy of the cells array
    const newCells = [...this.currentState.cells];
    const newBoard = [...this.currentState.board];
    
    // Clear the cell
    newCells[cellIndex] = null;
    newBoard[cellIndex] = null;
    
    this.updateState({
      cells: newCells,
      board: newBoard
    });
  }

  private setupStateSync(): void {
    if (!this.gameId || this.localStateOnly) return;
    
    console.log("Setting up state synchronization with FastAPI WebSocket");
    
    // Close existing connection if any
    if (this.wsConnection) {
      this.wsConnection.close();
    }
    
    // Connect to WebSocket
    this.wsConnection = new WebSocket(`${this.wsUrl}/ws/games/${this.gameId}`);
    
    this.wsConnection.onopen = () => {
      console.log("WebSocket connection established");
      
      // Send player info to join the game
      if (this.wsConnection) {
        this.wsConnection.send(JSON.stringify({
          type: "join",
          player_id: this.playerId
        }));
      }
    };
    
    this.wsConnection.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        
        if (data.type === "game_update") {
          // Received updated state from server, update local state
          console.log("Received updated state from server:", data);
          
          // Check if the update is newer than our current state
          if (!this.currentState.timestamp || data.timestamp > this.currentState.timestamp) {
            this.stateLock = true;
            
            this.currentState = {
              ...this.currentState,
              board: data.board,
              cells: data.board,
              currentPlayer: data.current_player,
              gamePhase: data.game_phase,
              winner: data.game_result?.winner,
              isDraw: data.game_result?.is_draw,
              lastMove: data.last_move?.cell_index || null,
              timestamp: data.timestamp
            };
            
            this.notifySubscribers();
            this.stateLock = false;
          } else {
            console.log("Ignoring outdated state update from server");
          }
        }
      } catch (error) {
        console.error("Error processing WebSocket message:", error);
      }
    };
    
    this.wsConnection.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
    
    this.wsConnection.onclose = () => {
      console.log("WebSocket connection closed");
      
      // Attempt to reconnect after a delay
      setTimeout(() => {
        if (this.gameId && !this.localStateOnly) {
          this.setupStateSync();
        }
      }, 3000);
    };
  }

  private notifySubscribers(): void {
    const state = this.getState();
    this.subscribers.forEach(callback => callback(state));
  }

  public expandBoard(): void {
    // Lock state during expansion
    this.stateLock = true;
    
    // Mark as expanding
    this.updateState({ isExpanding: true });
    
    // Update boardSize
    this.boardSize = this.expandedBoardSize;
    
    setTimeout(() => {
      // Create expanded board
      const expandedCells = Array(this.boardSize * this.boardSize).fill(null);
      const oldSize = this.currentState.boardSize;
      const offset = Math.floor((this.boardSize - oldSize) / 2);
      
      // Copy existing board to center of new board
      for (let y = 0; y < oldSize; y++) {
        for (let x = 0; x < oldSize; x++) {
          const oldIndex = y * oldSize + x;
          const newIndex = (y + offset) * this.boardSize + (x + offset);
          expandedCells[newIndex] = this.currentState.cells[oldIndex];
        }
      }
      
      // Block winning moves
      this.blockPotentialWins();
      
      // Update state with expanded board
      this.updateState({
        cells: expandedCells,
        board: expandedCells,
        boardSize: this.boardSize,
        isExpanding: false,
        isExpanded: true
      });
      
      this.stateLock = false;
    }, 1000); // Animation delay
  }

  private blockPotentialWins(): void {
    // Implementation to block potential wins when board expands
    // This is a simplified version
    const size = this.boardSize;
    const expandedCells = Array(size * size).fill(null);
    const oldSize = this.currentState.boardSize;
    const offset = Math.floor((size - oldSize) / 2);
    
    // Copy existing board to center
    for (let y = 0; y < oldSize; y++) {
      for (let x = 0; x < oldSize; x++) {
        const oldIndex = y * oldSize + x;
        const newIndex = (y + offset) * size + (x + offset);
        expandedCells[newIndex] = this.currentState.cells[oldIndex];
      }
    }
    
    // Check for potential wins and block them
    for (let y = 0; y < size; y++) {
      for (let x = 0; x < size; x++) {
        const index = y * size + x;
        if (expandedCells[index] === null) {
          // Check if this position would create a win for either player
          if (
            this.checkForWinningMove('X', y, x) || 
            this.checkForWinningMove('O', y, x)
          ) {
            expandedCells[index] = 'B'; // Block this cell
          }
        }
      }
    }
    
    // Update cells
    this.currentState.cells = expandedCells;
    this.currentState.board = expandedCells;
  }

  private checkForWinningMove(symbol: string, row: number, col: number): boolean {
    // Check horizontal
    let count = 0;
    for (let x = Math.max(0, col - 2); x < Math.min(this.boardSize, col + 3); x++) {
      const index = row * this.boardSize + x;
      if (this.currentState.cells[index] === symbol || this.currentState.cells[index] === null) {
        count++;
        if (count >= 3) return true;
      } else {
        count = 0;
      }
    }

    // Check vertical
    count = 0;
    for (let y = Math.max(0, row - 2); y < Math.min(this.boardSize, row + 3); y++) {
      const index = y * this.boardSize + col;
      if (this.currentState.cells[index] === symbol || this.currentState.cells[index] === null) {
        count++;
        if (count >= 3) return true;
      } else {
        count = 0;
      }
    }

    // Check diagonal down
    count = 0;
    for (let i = -2; i <= 2; i++) {
      const y = row + i;
      const x = col + i;
      if (y >= 0 && y < this.boardSize && x >= 0 && x < this.boardSize) {
        const index = y * this.boardSize + x;
        if (this.currentState.cells[index] === symbol || this.currentState.cells[index] === null) {
          count++;
          if (count >= 3) return true;
        } else {
          count = 0;
        }
      }
    }

    // Check diagonal up
    count = 0;
    for (let i = -2; i <= 2; i++) {
      const y = row + i;
      const x = col - i;
      if (y >= 0 && y < this.boardSize && x >= 0 && x < this.boardSize) {
        const index = y * this.boardSize + x;
        if (this.currentState.cells[index] === symbol || this.currentState.cells[index] === null) {
          count++;
          if (count >= 3) return true;
        } else {
          count = 0;
        }
      }
    }

    return false;
  }

  // Clean up resources when component unmounts
  public cleanup(): void {
    if (this.wsConnection) {
      this.wsConnection.close();
      this.wsConnection = null;
    }
  }

  /**
   * Resizes the board without adding blocked cells 
   * Used when receiving expansion data from server
   */
  public resizeBoardOnly(newSize: number): void {
    console.log(`Resizing board to ${newSize}x${newSize} without generating blocked cells`);
    
    // Save the current board
    const oldBoard = this.currentState.board.slice();
    const oldSize = this.boardSize;
    
    // Update board size
    this.boardSize = newSize;
    const newCells = Array(this.boardSize * this.boardSize).fill(null);
    
    // Calculate the offset to center the old board in the new one
    const offset = Math.floor((this.boardSize - oldSize) / 2);
    
    // Copy the old board values to the center of the new board
    for (let i = 0; i < oldSize; i++) {
      for (let j = 0; j < oldSize; j++) {
        const oldIndex = i * oldSize + j;
        const newIndex = (i + offset) * this.boardSize + (j + offset);
        newCells[newIndex] = oldBoard[oldIndex];
      }
    }
    
    // Update state with resized board
    this.updateState({
      cells: newCells,
      board: newCells,
      boardSize: this.boardSize,
      isExpanding: false,
      isExpanded: true
    });
    
    console.log('Board resized successfully without adding blocked cells');
  }

  /**
   * Resets the board state to match the provided data from the server
   * This is used when receiving a board expansion from another client
   */
  public resetBoardState(boardSize: number, boardData: string[]): void {
    console.log('Resetting board state to match server data', boardSize, boardData);
    
    // Update board size
    this.boardSize = boardSize;
    
    // Create a new 1D array for the board
    const newCells = Array(this.boardSize * this.boardSize).fill(null);
    
    // Fill in the board with the provided data
    for (let i = 0; i < boardSize; i++) {
      for (let j = 0; j < boardSize; j++) {
        const index = i * boardSize + j;
        if (index < boardData.length) {
          const cell = boardData[index];
          if (cell === '_') {
            newCells[index] = null;
          } else if (cell === 'B') {
            newCells[index] = 'blocked';
          } else {
            newCells[index] = cell as 'X' | 'O'; // 'X' or 'O'
          }
        }
      }
    }
    
    // Update state with reset board
    this.updateState({
      cells: newCells,
      board: newCells,
      boardSize: this.boardSize,
      isExpanding: false,
      isExpanded: true
    });
    
    console.log('Board state reset complete. New board:', newCells);
  }

  public findImmediateWinningMoves(playerSymbol: 'X' | 'O'): {row: number, col: number}[] {
    const winningMoves: {row: number, col: number}[] = [];

    for (let r = 0; r < this.boardSize; r++) {
      for (let c = 0; c < this.boardSize; c++) {
        if (this.currentState.cells[r * this.boardSize + c] === null) { // Check only empty cells
          // Temporarily make the move *without* modifying this.currentState.cells
          this.currentState.cells[r * this.boardSize + c] = playerSymbol; // Temporarily modify for the check
          
          // Check if this move wins using the internal check
          const result = this._checkWinCondition(); 
          if (result.winner === playerSymbol) {
            winningMoves.push({ row: r, col: c });
          }
          
          // Revert the temporary move
          this.currentState.cells[r * this.boardSize + c] = null; // IMPORTANT: Undo the modification
        }
      }
    }
    
    // this.currentState.cells = originalCells; // No longer needed

    // --- Start Modification ---
    console.log(`Found immediate winning moves for ${playerSymbol}:`, winningMoves); // Add logging
    // --- End Modification ---
    return winningMoves;
  }

  private _checkWinCondition(): { winner: 'X' | 'O' | null, winningLine: {row: number, col: number}[] } {
    // Win condition is always 3-in-a-row
    const winLength = 3;

    // Check rows, columns, and diagonals
    for (let i = 0; i < this.boardSize; i++) {
      for (let j = 0; j < this.boardSize; j++) {
        const symbol = this.currentState.cells[i * this.boardSize + j];
        if (symbol === null || symbol === 'blocked') continue;

        // Check horizontal, vertical, and diagonals starting from (i, j)
        const directions = [
          { dr: 0, dc: 1 }, // Horizontal
          { dr: 1, dc: 0 }, // Vertical
          { dr: 1, dc: 1 }, // Diagonal down-right
          { dr: 1, dc: -1 } // Diagonal down-left
        ];

        for (const { dr, dc } of directions) {
          const line = [];
          let win = true;
          for (let k = 0; k < winLength; k++) {
            const r = i + k * dr;
            const c = j + k * dc;
            // Check bounds and if the symbol matches
            if (r < 0 || r >= this.boardSize || c < 0 || c >= this.boardSize || 
                this.currentState.cells[r * this.boardSize + c] !== symbol || this.currentState.cells[r * this.boardSize + c] === 'blocked') {
              win = false;
              break;
            }
            line.push({ row: r, col: c });
          }
          if (win) {
            return { winner: symbol, winningLine: line };
          }
        }
      }
    }
    return { winner: null, winningLine: [] };
  }

  public checkWin(): boolean {
    const result = this._checkWinCondition(); // Use the internal check
    if (result.winner) {
      this.currentState.winner = result.winner;
      this.currentState.winningLine = result.winningLine;
      this.currentState.gamePhase = GamePhase.GAME_OVER;
      return true;
    }
    return false;
  }

  public isBoardFull(): boolean {
    return this.currentState.cells.every(cell => cell !== null);
  }

  public setBoardSize(size: number): void {
    this.boardSize = size;
    this.currentState.boardSize = size;
    // No need to reset the board array here, let the calling context handle repopulating
    console.log(`Board size set to ${size}x${size}`);
    this.notifySubscribers(); // Notify about size change if needed
  }
}

export class GameState {
  private board: (string | null)[][];
  public phase: GamePhase;
  private currentPlayer: 'X' | 'O';
  private winner: string | null;
  private boardSize: number;
  public isExpanded: boolean = false;
  private winningLine: {row: number, col: number}[] = [];

  constructor() {
    console.log('Initializing GameState');
    this.boardSize = 3;
    this.board = Array(this.boardSize).fill(null).map(() => Array(this.boardSize).fill(null));
    this.phase = GamePhase.PLAYER_TURN;
    this.currentPlayer = 'X';
    this.winner = null;
    this.isExpanded = false;
  }

  public reset(): void {
    console.log('Resetting game state');
    this.boardSize = 3;
    this.board = Array(this.boardSize).fill(null).map(() => Array(this.boardSize).fill(null));
    this.phase = GamePhase.PLAYER_TURN;
    this.currentPlayer = 'X';
    this.winner = null;
    this.isExpanded = false;
  }

  public getBoard(): (string | null)[][] {
    return this.board.map(row => [...row]);
  }

  /**
   * Returns a flattened 1D array version of the board
   * This is used for multiplayer communication
   */
  public getBoardFlat(): (string | null)[] {
    const result: (string | null)[] = [];
    for (let i = 0; i < this.boardSize; i++) {
      for (let j = 0; j < this.boardSize; j++) {
        result.push(this.board[i][j]);
      }
    }
    return result;
  }

  public getCurrentPlayer(): 'X' | 'O' {
    return this.currentPlayer;
  }

  public getPhase(): GamePhase {
    return this.phase;
  }

  public getWinner(): string | null {
    return this.winner;
  }

  public getBoardSize(): number {
    return this.boardSize;
  }

  public getWinningLine(): {row: number, col: number}[] {
    return this.winningLine;
  }

  public isValidMove(row: number, col: number): boolean {
    return row >= 0 && row < this.boardSize && 
           col >= 0 && col < this.boardSize && 
           this.board[row][col] === null;
  }

  public makeMove(row: number, col: number): boolean {
    if (!this.isValidMove(row, col)) {
      return false;
    }

    this.board[row][col] = this.currentPlayer;

    // Check for win condition BEFORE switching players
    if (this.checkWin()) {
      this.phase = GamePhase.GAME_OVER;
      this.winner = this.currentPlayer;
      return true;
    }

    // Switch players
    this.currentPlayer = this.currentPlayer === 'X' ? 'O' : 'X';
    this.phase = this.currentPlayer === 'X' ? GamePhase.PLAYER_TURN : GamePhase.AI_TURN;

    return true;
  }

  public makeCustomMove(row: number, col: number, symbol: 'X' | 'O' | 'blocked'): boolean {
    if (!this.isValidMove(row, col)) {
      return false;
    }

    this.board[row][col] = symbol;
    return true;
  }

  /**
   * Checks for a win condition based on the current board size.
   * Looks for N-in-a-row where N is the board size (3 for 3x3, 5 for 5x5).
   * @private
   */
  private _checkWinCondition(): { winner: 'X' | 'O' | null, winningLine: {row: number, col: number}[] } {
    // Win condition is always 3-in-a-row
    const winLength = 3;

    // Check rows, columns, and diagonals
    for (let i = 0; i < this.boardSize; i++) {
      for (let j = 0; j < this.boardSize; j++) {
        const symbol = this.board[i][j];
        if (symbol === null || symbol === 'blocked') continue;

        // Check horizontal, vertical, and diagonals starting from (i, j)
        const directions = [
          { dr: 0, dc: 1 }, // Horizontal
          { dr: 1, dc: 0 }, // Vertical
          { dr: 1, dc: 1 }, // Diagonal down-right
          { dr: 1, dc: -1 } // Diagonal down-left
        ];

        for (const { dr, dc } of directions) {
          const line = [];
          let win = true;
          for (let k = 0; k < winLength; k++) {
            const r = i + k * dr;
            const c = j + k * dc;
            // Check bounds and if the symbol matches
            if (r < 0 || r >= this.boardSize || c < 0 || c >= this.boardSize || this.board[r][c] !== symbol) {
              win = false;
              break;
            }
            line.push({ row: r, col: c });
          }
          if (win) {
            return { winner: symbol, winningLine: line };
          }
        }
      }
    }
    return { winner: null, winningLine: [] };
  }

  /**
   * Finds all empty cells that would result in an immediate win for the given player.
   * Uses the current board size to determine win condition (3 or 5 in a row).
   */
  public findImmediateWinningMoves(playerSymbol: 'X' | 'O'): {row: number, col: number}[] {
    const winningMoves: {row: number, col: number}[] = [];

    for (let r = 0; r < this.boardSize; r++) {
      for (let c = 0; c < this.boardSize; c++) {
        if (this.board[r][c] === null) { // Check only empty cells
          // Temporarily make the move *without* modifying this.board
          this.board[r][c] = playerSymbol; // Temporarily modify for the check
          
          // Check if this move wins using the internal check
          const result = this._checkWinCondition(); 
          if (result.winner === playerSymbol) {
            winningMoves.push({ row: r, col: c });
          }
          
          // Revert the temporary move
          this.board[r][c] = null; // IMPORTANT: Undo the modification
        }
      }
    }
    
    // this.board = originalBoard; // No longer needed

    // --- Start Modification ---
    console.log(`Found immediate winning moves for ${playerSymbol}:`, winningMoves); // Add logging
    // --- End Modification ---
    return winningMoves;
  }

  public checkWin(): boolean {
    const result = this._checkWinCondition(); // Use the internal check
    if (result.winner) {
      this.winner = result.winner;
      this.winningLine = result.winningLine;
      this.phase = GamePhase.GAME_OVER;
      return true;
    }
    return false;
  }

  public isBoardFull(): boolean {
    return this.board.every(row => row.every(cell => cell !== null));
  }

  public setBoardSize(size: number): void {
    console.log(`Setting board size to ${size}`);
    if (size === this.boardSize) {
      return; // No change needed
    }
    
    // Save current board state
    const oldBoard = this.getBoard();
    const oldSize = this.boardSize;
    
    // Set new board size
    this.boardSize = size;
    
    // Create new empty board
    this.board = Array(size).fill(null).map(() => Array(size).fill(null));
    
    // If expanding, copy old board to center of new board
    if (size > oldSize) {
      const offset = Math.floor((size - oldSize) / 2);
      
      for (let i = 0; i < oldSize; i++) {
        for (let j = 0; j < oldSize; j++) {
          if (oldBoard[i][j] !== null) {
            this.board[i + offset][j + offset] = oldBoard[i][j];
          }
        }
      }
      
      this.isExpanded = true;
    }
    
    console.log(`Board size set to ${size}. New board:`, this.board);
  }
  
  public expandBoard(): void {
    console.log('Expanding board');
    // Default expansion from 3x3 to 6x6
    const newSize = this.boardSize === 3 ? 6 : this.boardSize * 2;
    this.setBoardSize(newSize);
    
    // --- Start Modification ---
    // Remove the hardcoded blocking. Dynamic blocking happens in Game.ts
    // this.addBlockedCells();
    // --- End Modification ---
    
    console.log('Board expanded successfully in GameState');
  }
}