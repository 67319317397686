import { AssetLoader } from './assetLoader';
import { GameState, GamePhase } from './gameState';
import { AI } from './ai';
import { Menu } from './menu';
import { authService } from './services/authService';
import { BoardRenderer } from './managers/BoardRenderer';
import { InputManager } from './managers/InputManager';
import { UIManager } from './managers/UIManager';
import { MultiplayerManager } from './managers/MultiplayerManager';
import { WebSocketManager } from './managers/WebSocketManager';
import { GameMode, GameStartEvent, WinningLine } from './types/GameTypes';

// Define valid player symbols as a type
type PlayerSymbol = 'X' | 'O';

export class Game {
  private assetLoader: AssetLoader;
  private gameState: GameState;
  private ai: AI | null = null;
  private menu: Menu;
  private boardRenderer: BoardRenderer;
  private inputManager: InputManager;
  private uiManager: UIManager;
  private multiplayerManager: MultiplayerManager;
  
  private isAIMode: boolean = false;
  private isPlayerTurn: boolean = true;
  private gameStarted: boolean = false;
  private isRankedMode: boolean = false;
  private username: string | null = null;
  private opponent: string | null = null;
  private currentGameId: string | null = null;
  private playerSymbol: 'X' | 'O' | null = null;
  private isGameOver: boolean = false;

  constructor(assetLoader: AssetLoader) {
    console.log('Game constructor called');
    
    // Save asset loader reference
    this.assetLoader = assetLoader;
    
    // Initialize the game state
    this.gameState = new GameState();
    
    // Initialize components
    this.boardRenderer = new BoardRenderer(assetLoader);
    this.uiManager = new UIManager();
    this.inputManager = new InputManager(this.boardRenderer);
    this.multiplayerManager = new MultiplayerManager(this.uiManager, assetLoader);
    
    // Initialize board but keep it hidden initially
    this.boardRenderer.createBoard();
    this.boardRenderer.hideBoard();
    
    // Set up input handling
    this.setupInputHandlers();
    
    // Initialize menu last (so it's on top)
    this.menu = new Menu();
    this.menu.show();
    
    // Set up event listeners
    document.addEventListener('startGame', this.handleStartGame.bind(this) as EventListener);
    document.addEventListener('menu-show', this.onMenuShow.bind(this) as EventListener);
  }

  private setupInputHandlers(): void {
    this.inputManager.setClickCallback((row, col, _cellIndex) => {
      this.handlePlayerInput(row, col);
    });
    this.inputManager.disable();
  }

  private handleStartGame(event: CustomEvent<GameStartEvent>): void {
    const { mode, username } = event.detail || {};
    
    console.log(`Starting game in ${mode} mode`);
    
    // Reset any existing game state
    this.reset();
    
    // Setup for the selected game mode
    switch (mode) {
      case GameMode.AI:
        this.startAIGame();
        break;
      case GameMode.LOCAL:
        this.startLocalGame();
        break;
      case GameMode.RANKED:
        this.username = username || `Player-${Math.floor(Math.random() * 1000)}`;
        this.startRankedGame();
        break;
    }
  }

  private startAIGame(): void {
    console.log('Starting AI game');
    
    // --- Ensure board visual state is reset --- 
    this.boardRenderer.createBoard(3); 
    // ------------------------------------------

    this.isAIMode = true;
    this.isRankedMode = false;
    this.gameStarted = true;
    this.isPlayerTurn = true;
    
    // Set player as X and AI as O
    this.playerSymbol = 'X';
    
    // Initialize the game state
    this.gameState = new GameState();
    this.gameState.phase = GamePhase.PLAYER_TURN;
    
    // Initialize the AI with O symbol
    this.ai = new AI();
    
    // Show board
    this.boardRenderer.showBoard();
    
    // Delay enabling input slightly
    setTimeout(() => {
        if (this.gameStarted && this.isAIMode) { // Check game mode still matches
             console.log("Enabling input for AI game after delay.");
             this.inputManager.enable();
        }
    }, 100);
    
    // Update the turn indicator
    this.uiManager.updateTurnIndicator('YOUR TURN', '#00ff00');
    
    // Start background music
    this.assetLoader.playBackgroundMusic();
  }

  private startLocalGame(): void {
    console.log('Starting local game');
    
    // --- Ensure board visual state is reset --- 
    this.boardRenderer.createBoard(3);
    // ------------------------------------------

    this.isAIMode = false;
    this.isRankedMode = false;
    this.gameStarted = true;
    this.isPlayerTurn = true;
    this.gameState.phase = GamePhase.PLAYER_TURN;
    
    // No AI in local game
    this.ai = null;
    
    // Show board
    this.boardRenderer.showBoard();
    
    // Delay enabling input slightly to prevent capturing UI clicks
    setTimeout(() => {
        if (this.gameStarted && !this.isRankedMode && !this.isAIMode) { // Check game mode still matches
             console.log("Enabling input for local game after delay.");
             this.inputManager.enable();
        }
    }, 100); // 100ms delay
    
    // Update the turn indicator
    this.uiManager.updateTurnIndicator("PLAYER X'S TURN", '#00ff00');
    
    // Start background music
    this.assetLoader.playBackgroundMusic();
  }

  private startRankedGame(): void {
    console.log('Starting ranked game...');
    
    this.isAIMode = false;
    this.isRankedMode = true;
    
    if (!this.username) {
      console.error('Cannot start ranked game without a username');
      this.uiManager.showErrorMessage('Cannot start ranked game: Please log in first');
      this.menu.show();
      return;
    }
    
    // Start matchmaking
    this.multiplayerManager.startMatchmaking({
      username: this.username,
      onMatchFound: (gameId, opponent) => {
        this.opponent = opponent;
        this.currentGameId = gameId;
        
        // Hide the waiting screen now that a match is found
        this.uiManager.hideWaitingScreen();
        
        // Show match summary
        this.uiManager.showMatchSummary({
          player1: this.username || 'You',
          player2: opponent
        });
        
        // Start match after a short delay
        setTimeout(() => {
          this.uiManager.hideMatchSummary();
          this.startMatch(gameId);
        }, 3000);
      },
      onError: (message) => {
        this.uiManager.showErrorMessage(message);
        this.menu.show();
      },
      onReconnect: (gameId, opponent) => {
        this.opponent = opponent;
        this.currentGameId = gameId;
        
        // Show reconnection message
        this.uiManager.updateWaitingScreen(`Reconnecting to game with ${opponent}`);
        
        // Start match after a short delay
        setTimeout(() => {
          this.uiManager.hideWaitingScreen();
          this.startMatch(gameId);
        }, 1500);
      }
    });
  }

  private startMatch(gameId: string): void {
    console.log(`Starting match with gameId: ${gameId}`);
    
    this.gameStarted = true;
    
    // Initialize game state
    this.gameState = new GameState();
    
    // Create the board and show it
    this.boardRenderer.createBoard();
    this.boardRenderer.showBoard();
    
    // Show connecting message
    this.uiManager.updateTurnIndicator('CONNECTING...', '#ffff00');
    
    // Start background music
    this.assetLoader.playBackgroundMusic();
    
    // Join the game
    this.multiplayerManager.joinGame({
      gameId,
      username: this.username || 'Guest',
      onGameUpdate: (board, currentPlayer) => {
        this.updateBoardFromServer(board);
        
        // Update turn based on current player from server
        if (this.playerSymbol) {
          // It's our turn if currentPlayer matches our symbol
          this.isPlayerTurn = (currentPlayer === this.playerSymbol);
          
          // Update turn indicator
          this.uiManager.updatePlayerTurn(this.isPlayerTurn, this.playerSymbol);
          
          // Enable/disable input based on turn
          if (this.isPlayerTurn) {
            this.inputManager.enable();
          } else {
            this.inputManager.disable();
          }
        }
      },
      onPlayerInfo: (playerSymbol, isPlayerTurn) => {
        this.playerSymbol = playerSymbol;
        this.isPlayerTurn = isPlayerTurn;
        
        // Update turn indicator with player symbol
        this.uiManager.updatePlayerTurn(isPlayerTurn, playerSymbol);
        
        // Enable/disable input based on turn
        if (isPlayerTurn) {
          this.inputManager.enable();
        } else {
          this.inputManager.disable();
        }
      },
      onMoveMade: (cellIndex: number, symbol: string, board?: (string | null)[]) => {
        // If a full board was provided, update the entire board
        if (board) {
          this.updateBoardFromServer(board);
        } else {
          // Otherwise just update the specific cell
          // Use the type guard function
          if (this.isValidPlayerSymbol(symbol)) {
            this.updateCellFromServer(cellIndex, symbol);
          }
        }
        
        // Check if it's our turn after the move
        if (this.playerSymbol) {
          const otherPlayer = this.playerSymbol === 'X' ? 'O' : 'X';
          const movingPlayer = symbol;
          
          // If the last move was from the other player, it's now our turn
          if (movingPlayer === otherPlayer) {
            this.isPlayerTurn = true;
            // Update turn indicator
            this.uiManager.updatePlayerTurn(true, this.playerSymbol);
            // Enable input
            this.inputManager.enable();
          } else {
            this.isPlayerTurn = false;
            // Update turn indicator
            this.uiManager.updatePlayerTurn(false, this.playerSymbol);
            // Disable input
            this.inputManager.disable();
          }
        }
      },
      onBoardExpand: (boardSize, board, currentPlayer) => {
        console.log(`Expanding board to ${boardSize}x${boardSize}`);
        
        // Update game state
        this.gameState.setBoardSize(boardSize);
        
        // Update board renderer
        this.boardRenderer.resizeBoard(boardSize);
        
        // Update the board visuals
        this.updateBoardFromServer(board);

        // Update turn state based on server
        if (this.playerSymbol) {
          this.isPlayerTurn = (currentPlayer === this.playerSymbol);
          this.uiManager.updatePlayerTurn(this.isPlayerTurn, this.playerSymbol);
          if (this.isPlayerTurn) {
            console.log("Enabling input after board expansion (Your Turn)");
            this.inputManager.enable();
          } else {
            console.log("Disabling input after board expansion (Opponent's Turn)");
            this.inputManager.disable();
          }
        }
      },
      onGameWin: (winnerSymbol, winningLine) => {
        const isWinner = winnerSymbol === this.playerSymbol;
        
        // Make sure the winning line cells are filled with the winning symbol
        // This ensures the last move is always visible
        if (winningLine && winningLine.length > 0) {
          winningLine.forEach(cell => {
            const index = cell.row * this.gameState.getBoardSize() + cell.col;
            this.boardRenderer.updateCell(index, winnerSymbol as PlayerSymbol);
          });
        }
        
        // Animate the winning line
        this.boardRenderer.animateWinningLine(winnerSymbol as PlayerSymbol, winningLine);
        
        // Mark game as over
        this.isGameOver = true;
        
        // Show appropriate game over message
        const message = isWinner ? 'You Win!' : 'You Lose!';
        
        // Update the turn indicator to show who won
        const winnerName = isWinner ? 'YOU WIN!' : 
          (this.opponent ? `${this.opponent.toUpperCase()} WINS!` : 'OPPONENT WINS!');
        
        this.uiManager.updateTurnIndicator(winnerName, isWinner ? '#00ff00' : '#ff3333');
        
        // End the game after a short delay
        setTimeout(() => {
          this.endRankedGame(message, isWinner);
        }, 3000);
      },
      onOpponentDisconnect: () => {
        if (this.isGameOver) return; // Ignore if game is already over
        
        this.uiManager.showErrorMessage('Opponent disconnected');
        
        // End the game
        setTimeout(() => {
          this.endRankedGame('Opponent Disconnected', true);
        }, 1500);
      },
      onConnectionLost: () => {
        if (this.isGameOver) return; // Ignore if game is already over
        
        this.uiManager.showErrorMessage('Connection to server lost');
        
        // Go back to menu
        setTimeout(() => {
          this.reset();
          this.menu.show();
        }, 1500);
      }
    });
  }

  private handleRankedMove(cellIndex: number): void {
    if (!this.isPlayerTurn || !this.isRankedMode) return;
    
    // Disable input while waiting for server response
    this.inputManager.disable();
    
    // Get row and column from cell index
    const boardSize = this.gameState.getBoardSize();
    const row = Math.floor(cellIndex / boardSize);
    const col = cellIndex % boardSize;
    
    // Update visual state immediately for better user experience
    // This will be confirmed by the server response later
    if (this.playerSymbol) {
      this.boardRenderer.updateCell(cellIndex, this.playerSymbol);
    }
    
    // Send move to server
    const success = this.multiplayerManager.makeMove(cellIndex);
    
    if (!success) {
      console.error('Failed to send move to server');
      
      // If move failed, revert the visual change by resetting the board
      this.updateBoardFromServer(this.gameState.getBoardFlat());
      
      // Re-enable input
      this.inputManager.enable();
      
      // Show error
      this.uiManager.showErrorMessage('Failed to send move');
    }
  }

  /**
   * Handles applying a move to the game state and visual board.
   * This is called by both player input and AI decisions.
   * @param row Row index of the move
   * @param col Column index of the move
   * @param symbol The symbol to place ('X', 'O', or 'blocked')
   */
  private handleMoveExecution(row: number, col: number, symbol: PlayerSymbol | 'blocked'): void {
    let moveSuccess = false;
    if (symbol === 'blocked') {
      moveSuccess = this.gameState.makeCustomMove(row, col, 'blocked');
    } else {
      // Make move in game state - NOTE: This switches the internal player!
      moveSuccess = this.gameState.makeMove(row, col);
    }

    if (!moveSuccess) {
      console.warn(`Move execution failed for ${symbol} at ${row},${col}`);
      return; // Move was invalid (e.g., cell occupied)
    }
    
    // Play sound effect only for player/AI moves, not blocking
    if (symbol !== 'blocked') {
      this.assetLoader.playSoundEffect('place');
    }
    
    // Get cell index
    const boardSize = this.gameState.getBoardSize();
    const cellIndex = row * boardSize + col;
    
    // Update visual state with the placed symbol
    this.boardRenderer.updateCell(cellIndex, symbol);
    
    // Check for win
    const checkResult = this.gameState.checkWin(); // This updates internal state if win occurred
    if (checkResult) {
      const winner = symbol; // The player who just moved is the winner
      const winnerName = 
        symbol === 'O' ? (this.isAIMode ? 'AI' : 'Player O') : 
        (this.isAIMode ? 'Player' : 'Player X');
      
      // Play win sound
      this.assetLoader.playSoundEffect('win');
      
      // Animate the winning line
      const winningLine = this.gameState.getWinningLine();
      this.boardRenderer.animateWinningLine(winner as PlayerSymbol, winningLine);
      
      // End game after animation
      setTimeout(() => {
        this.endGame(`${winnerName} wins!`);
      }, 3000);
      
      return; // Game over
    }
    
    // Check for board expansion
    if (this.gameState.isBoardFull()) {
      if (!this.gameState.isExpanded) {
        // Expand the board (state and visual)
        this.expandBoardProcedure(); 
        // After expansion, the turn logic proceeds below
      } else {
        // Game is a draw (board full and already expanded)
        setTimeout(() => {
          this.endGame('Draw!');
        }, 1000);
        return; // Game over
      }
    }
    
    // --- Switch turns and update UI/Input --- 
    // Only switch turns if a real move ('X' or 'O') was made, not a block
    if (symbol !== 'blocked') {
      // Toggle turn ONLY in AI mode
      if (this.isAIMode) {
        this.isPlayerTurn = !this.isPlayerTurn;
      }

      // Check if it's AI's turn NOW (after potential toggle)
      if (this.isAIMode && !this.isPlayerTurn) {
        this.uiManager.updateTurnIndicator('AI THINKING...', '#ff6666');
        this.inputManager.disable();
        setTimeout(() => this.makeAIMove(), 500);
      } else {
        // It's either the Player's turn in AI mode, OR it's Local mode (where isPlayerTurn remains true)
        const nextPlayerDisplay = this.isAIMode ? 'YOUR TURN' : `PLAYER ${this.gameState.getCurrentPlayer()}'S TURN`;
        const color = this.isAIMode || this.gameState.getCurrentPlayer() === 'X' ? '#00ff00' : '#ff6666';
        this.uiManager.updateTurnIndicator(nextPlayerDisplay, color);
        this.inputManager.enable(); // Enable for Player (AI mode) or Local player
      }
    } else { // Handle 'blocked' case (e.g., during expansion)
      // If a block was placed, the turn doesn't switch. Update UI and input state accordingly.
      if (this.isAIMode) {
         // If a block happened during AI's turn sequence, keep AI thinking/input disabled
         if (!this.isPlayerTurn) {
            this.uiManager.updateTurnIndicator('AI THINKING...', '#ff6666');
            this.inputManager.disable();
         } else {
            // This case (block during player turn in AI mode) shouldn't happen with current logic, but handle defensively
            this.uiManager.updateTurnIndicator('YOUR TURN', '#00ff00');
            this.inputManager.enable();
         }
      } else { // Local mode
        // Keep input enabled for the player whose turn it still is
        const currentPlayerDisplay = `PLAYER ${this.gameState.getCurrentPlayer()}'S TURN`;
        const color = this.gameState.getCurrentPlayer() === 'X' ? '#00ff00' : '#ff6666';
        this.uiManager.updateTurnIndicator(currentPlayerDisplay, color);
        this.inputManager.enable();
      }
    }
  }

  /**
   * Encapsulates the logic for expanding the board state and visuals.
   */
  private expandBoardProcedure(): void {
    console.log('Board is full, expanding...');
    this.gameState.expandBoard(); // Expand state first
        
    // Rebuild the visual board
    this.boardRenderer.resizeBoard(this.gameState.getBoardSize());
    
    // Update each cell visually from the new game state
    const board = this.gameState.getBoard();
    const newSize = this.gameState.getBoardSize();
    for (let i = 0; i < newSize; i++) {
      for (let j = 0; j < newSize; j++) {
        const value = board[i][j];
        const cellIndex = i * newSize + j;
        if (value === 'X' || value === 'O' || value === 'blocked') {
          this.boardRenderer.updateCell(cellIndex, value);
        } else {
           // Value is null, updateCell now handles this directly
           this.boardRenderer.updateCell(cellIndex, null); 
        }
      }
    }

    // After expansion, immediately check and block potential winning moves
    const blockedMoves: {row: number, col: number}[] = [];
    const currentBoardState = this.gameState.getBoard();

    // Check each empty cell for potential wins
    for (let i = 0; i < newSize; i++) {
      for (let j = 0; j < newSize; j++) {
        if (this.gameState.isValidMove(i, j)) {
          let foundBlock = false;

          // Check if placing 'X' here would win
          currentBoardState[i][j] = 'X';
          const xWins = this.checkForWin(currentBoardState, 'X');
          if (xWins) {
            console.log(`Blocking potential X win at [${i}, ${j}] during expansion`);
            blockedMoves.push({row: i, col: j});
            foundBlock = true;
          }
          currentBoardState[i][j] = null;

          // Check if placing 'O' here would win
          currentBoardState[i][j] = 'O';
          const oWins = this.checkForWin(currentBoardState, 'O');
          if (oWins && !foundBlock) {
            console.log(`Blocking potential O win at [${i}, ${j}] during expansion`);
            blockedMoves.push({row: i, col: j});
          }
          currentBoardState[i][j] = null;
        }
      }
    }

    // Block all found potential winning moves
    if (blockedMoves.length > 0) {
      console.log("Blocking potential winning cells during expansion:", blockedMoves);
      for (const move of blockedMoves) {
        this.handleMoveExecution(move.row, move.col, 'blocked');
      }
    }

    console.log('Board expansion procedure complete.');
  }

  // Player clicking a cell initiates a move
  private handlePlayerInput(row: number, col: number): void {
      if (!this.gameStarted || !this.isPlayerTurn) return;
      
      console.log(`Player clicking cell: ${row}, ${col}`);
      const playerSymbol = this.isAIMode ? (this.playerSymbol || 'X') : this.gameState.getCurrentPlayer();
      
      if (this.gameState.isValidMove(row, col)) {
        this.handleMoveExecution(row, col, playerSymbol); // Use the new execution function
      } else {
        console.log('Invalid move attempt by player.');
        // Maybe provide visual feedback for invalid move?
      }
  }

  private getAvailableMoves(): {row: number, col: number}[] {
    const moves: {row: number, col: number}[] = [];
    const size = this.gameState.getBoardSize();
    
    for (let i = 0; i < size; i++) {
      for (let j = 0; j < size; j++) {
        if (this.gameState.isValidMove(i, j)) {
          moves.push({row: i, col: j});
        }
      }
    }
    
    return moves;
  }

  private async makeAIMove(): Promise<void> {
    // Ensure it's AI's turn based on the Game state, not GameState's phase
    if (!this.isAIMode || this.isPlayerTurn) {
      console.warn('Attempted AI move when it was not AI turn.');
      return;
    }

    console.log('AI making move...');

    // Check for immediate win for AI ('O')
    const aiWinningMoves = this.gameState.findImmediateWinningMoves('O');
    if (aiWinningMoves.length > 0) {
      const move = aiWinningMoves[0];
      console.log(`AI found winning move: ${move.row}, ${move.col}`);
      this.handleMoveExecution(move.row, move.col, 'O'); // Use 'O' for AI win
      return;
    }

    // Check for player's winning moves ('X') and block them
    const playerWinningMoves = this.gameState.findImmediateWinningMoves('X');
    if (playerWinningMoves.length > 0) {
      const move = playerWinningMoves[0];
      console.log(`AI blocking player win at: ${move.row}, ${move.col}`);
      this.handleMoveExecution(move.row, move.col, 'O'); // Use 'O' to block
      return;
    }

    // If board is expanded, block potential winning moves for *both* players
    if (this.gameState.isExpanded) {
      const size = this.gameState.getBoardSize();
      const blockedMoves: {row: number, col: number}[] = [];
      
      // Create a temporary copy of the board for checking potential blocks
      // Avoid using gameState.getBoard() directly in the loop if it returns a new copy each time
      const currentBoardState = this.gameState.getBoard(); 

      for (let i = 0; i < size; i++) {
        for (let j = 0; j < size; j++) {
          // Check only *currently* empty cells in the actual game state
          if (this.gameState.isValidMove(i, j)) { 
            
            // --- Start Logging ---
            let foundBlock = false;
            // --- End Logging ---

            // Check if placing 'X' here would win
            currentBoardState[i][j] = 'X'; 
            const xWins = this.checkForWin(currentBoardState, 'X');
            // --- Start Logging ---
            if (xWins) {
              console.log(`---> Potential X win detected at [${i}, ${j}]`);
              blockedMoves.push({row: i, col: j});
              foundBlock = true;
            }
            // --- End Logging ---
            currentBoardState[i][j] = null; // Reset check

            // Check if placing 'O' here would win
            currentBoardState[i][j] = 'O'; 
            const oWins = this.checkForWin(currentBoardState, 'O');
            // --- Start Logging ---
            if (oWins) {
              // Only log if it wasn't already found as an X block
              if (!foundBlock) console.log(`---> Potential O win detected at [${i}, ${j}]`); 
              // Add to blockedMoves even if X would also win there (rare edge case)
              if (!blockedMoves.find(m => m.row === i && m.col === j)) {
                   blockedMoves.push({row: i, col: j});
              }
              foundBlock = true; 
            }
            // Log only if no win was found for this cell
            if (!foundBlock) {
              console.log(` - Checked [${i}, ${j}]: X wins=${xWins}, O wins=${oWins}`);
            }
            // --- End Logging ---
            currentBoardState[i][j] = null; // Reset check
          }
        }
      }

      // Block all found potential winning moves *before* making the final move
      if (blockedMoves.length > 0) {
          console.log("Blocking potential winning cells after expansion:", blockedMoves);
          for (const move of blockedMoves) {
              // Use handleMoveExecution to block visually and in state
              this.handleMoveExecution(move.row, move.col, 'blocked'); 
          }
      }
    }

    // Make a regular move if no immediate wins or blocks were executed
    const availableMoves = this.getAvailableMoves();
    if (availableMoves.length > 0) {
      const randomMove = availableMoves[Math.floor(Math.random() * availableMoves.length)];
      console.log(`AI making random move: ${randomMove.row}, ${randomMove.col}`);
      this.handleMoveExecution(randomMove.row, randomMove.col, 'O'); // Use 'O' for AI move
    } else {
        // No moves left - should trigger draw in handleMoveExecution if board is full
        console.log("AI found no available moves.");
        // If handleMoveExecution doesn't handle the draw case properly, we might need additional logic here
    }
  }

  private checkForWin(board: (string | null)[][], player: 'X' | 'O'): boolean {
    const size = board.length;
    // Always check for 3-in-a-row
    const winLength = 3; 

    // Check rows
    for (let i = 0; i < size; i++) {
      for (let j = 0; j <= size - winLength; j++) {
        let win = true;
        for (let k = 0; k < winLength; k++) {
          if (board[i][j + k] !== player) {
            win = false;
            break;
          }
        }
        if (win) return true;
      }
    }

    // Check columns
    for (let i = 0; i <= size - winLength; i++) {
      for (let j = 0; j < size; j++) {
        let win = true;
        for (let k = 0; k < winLength; k++) {
          if (board[i + k][j] !== player) {
            win = false;
            break;
          }
        }
        if (win) return true;
      }
    }

    // Check diagonals
    for (let i = 0; i <= size - winLength; i++) {
      for (let j = 0; j <= size - winLength; j++) {
        let win = true;
        for (let k = 0; k < winLength; k++) {
          if (board[i + k][j + k] !== player) {
            win = false;
            break;
          }
        }
        if (win) return true;
      }
    }

    // Check anti-diagonals
    for (let i = 0; i <= size - winLength; i++) {
      for (let j = winLength - 1; j < size; j++) {
        let win = true;
        for (let k = 0; k < winLength; k++) {
          if (board[i + k][j - k] !== player) {
            win = false;
            break;
          }
        }
        if (win) return true;
      }
    }

    return false;
  }

  private updateBoardFromServer(board: (string | null)[]): void {
    if (!board || board.length === 0) return;
    
    // Determine current board size
    const boardSize = Math.sqrt(board.length);
    
    // Check if board size changed
    if (boardSize !== this.gameState.getBoardSize()) {
      this.gameState.setBoardSize(boardSize);
      this.boardRenderer.resizeBoard(boardSize);
    }
    
    // Update each cell
    for (let i = 0; i < board.length; i++) {
      const value = board[i];
      
      // Skip empty cells
      if (value === null || value === '_') continue;
      
      // Calculate row and column
      const row = Math.floor(i / boardSize);
      const col = i % boardSize;
      
      if (value === 'B') {
        // Blocked cell
        this.gameState.makeCustomMove(row, col, 'blocked');
        this.boardRenderer.updateCell(i, 'blocked');
      } else if (value === 'X' || value === 'O') {
        // X or O symbol
        this.gameState.makeCustomMove(row, col, value);
        this.boardRenderer.updateCell(i, value);
      }
    }
  }

  private updateCellFromServer(cellIndex: number, symbol: PlayerSymbol): void {
    if (cellIndex < 0) return;
    
    // Get row and column from cell index
    const boardSize = this.gameState.getBoardSize();
    const row = Math.floor(cellIndex / boardSize);
    const col = cellIndex % boardSize;
    
    // Update game state
    this.gameState.makeCustomMove(row, col, symbol);
    
    // Update visual board
    this.boardRenderer.updateCell(cellIndex, symbol);
  }

  private endGame(message: string): void {
    if (this.isGameOver) return;
    
    console.log(`Game over: ${message}`);
    this.isGameOver = true;
    
    // Hide the board
    this.boardRenderer.hideBoard();
    
    // Disable input
    this.inputManager.disable();
    
    // Show game over screen
    this.uiManager.showGameOver(message, message.includes('win') && !message.includes('AI'), {
      playAgain: () => {
        this.reset();
        if (this.isAIMode) {
          this.startAIGame();
        } else {
          this.startLocalGame();
        }
      },
      backToMenu: () => {
        this.reset();
        this.menu.show();
      }
    });
    
    // Stop any ongoing game loops/timers
    this.gameStarted = false;
  }

  private endRankedGame(message: string, isWinner: boolean): void {
    // Mark game as over
    this.isGameOver = true;
    
    // Hide the board
    this.boardRenderer.hideBoard();
    
    // Disable input
    this.inputManager.disable();
    
    // End the multiplayer session
    this.multiplayerManager.endGame();
    
    // Show game over screen with ranked-specific options
    this.uiManager.showGameOver(message, isWinner, {
      playRankedAgain: () => {
        this.resetForNewRankedGame();
      },
      backToMenu: () => {
        this.reset();
        this.menu.show();
      }
    });
    
    // Stop any ongoing game loops/timers
    this.gameStarted = false;
  }

  private reset(): void {
    console.log('Resetting game state...');
    
    // Reset game state flags
    this.isGameOver = false;
    this.gameStarted = false;
    this.isPlayerTurn = true;
    
    // Reset multiplayer-specific state
    this.currentGameId = null;
    this.playerSymbol = null;
    this.opponent = null;
    
    // Clean up UI
    this.uiManager.clearAllUI();
    
    // Hide the board
    this.boardRenderer.hideBoard();
    
    // Reset game state
    this.gameState = new GameState();
    
    // Reset AI
    this.ai = null;
    
    // End any active multiplayer session
    this.multiplayerManager.endGame();
    
    // Disable input
    this.inputManager.disable();
    
    console.log('Game reset complete');
  }

  private resetForNewRankedGame(): void {
    console.log('Resetting for new ranked game...');
    
    // Keep username but reset other state
    const currentUsername = this.username;
    
    // Full reset
    this.reset();
    
    // Restore username
    this.username = currentUsername;
    
    // Start a new ranked game
    if (this.username) {
      console.log(`Starting new ranked game with username: ${this.username}`);
      this.startRankedGame();
    } else {
      console.error('Cannot restart ranked game: no username');
      this.menu.show();
    }
  }

  private onMenuShow(): void {
    console.log('Menu show event received');
    
    // Clean up the current game state
    this.reset();
  }

  public dispose(): void {
    console.log("Disposing game resources...");
    
    // Stop audio
    this.assetLoader.stopBackgroundMusic();
    
    // Remove event listeners
    document.removeEventListener('startGame', this.handleStartGame.bind(this) as EventListener);
    document.removeEventListener('menu-show', this.onMenuShow.bind(this) as EventListener);
    
    // Dispose of components
    this.boardRenderer.dispose();
    this.inputManager.dispose();
    this.uiManager.dispose();
    this.multiplayerManager.dispose();
    this.menu.dispose();
  }

  // Add this helper function to validate and convert symbols
  private isValidPlayerSymbol(symbol: string): symbol is PlayerSymbol {
    return symbol === 'X' || symbol === 'O';
  }
}