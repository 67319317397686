import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { AssetLoader } from '../assetLoader';
// Keep post-processing imports for later steps
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js'; // Keep for now, remove in Step 3
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'; // For Step 3
import { LineSegmentsGeometry } from 'three/examples/jsm/lines/LineSegmentsGeometry.js'; // For Step 2
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js'; // For Step 2
import { LineSegments2 } from 'three/examples/jsm/lines/LineSegments2.js'; // For Step 2
import { ExtrudeGeometry, Shape } from 'three';

// Tile User Data
interface TileUserData { row: number; col: number; isBlocked: boolean; }

// BoardTile material will be dark/transparent basic material
export interface BoardTile extends THREE.Mesh {
  userData: TileUserData;
  material: THREE.MeshBasicMaterial | THREE.MeshBasicMaterial[]; // Changed to Basic
}

// PieceMesh material will be bright basic material
export interface PieceMesh extends THREE.Mesh {
    material: THREE.MeshBasicMaterial | THREE.MeshBasicMaterial[]; // Changed to Basic
}

// WinningLine
export interface WinningLine { row: number; col: number; }

// Colors for the new theme (Step 1 & 2)
const COLOR_X = 0x00ff00; // Bright Green
const COLOR_O = 0xffff00; // Bright Yellow
const COLOR_GRID = 0x00ff00; // Brighter Green for grid lines (was 0x00cc00)
const COLOR_TILE = 0x555555; // Lighter grey for tiles (was 0x1a1a1a)
const COLOR_BASE = 0x050505; // Very dark grey / near black for base

export class BoardRenderer {
  // Core components
  private scene: THREE.Scene;
  private camera: THREE.PerspectiveCamera;
  private renderer: THREE.WebGLRenderer;
  private controls: OrbitControls;
  private assetLoader: AssetLoader;
  private board: THREE.Group; // Parent group for board elements

  // Board elements
  private boardBase: THREE.Mesh | null = null;
  private tileMeshes: BoardTile[] = [];
  private gridLines: LineSegments2 | null = null; // ADDED in Step 2
  private pieceMeshes: Map<number, THREE.Object3D> = new Map(); // Stores Mesh (O) or Group (X)

  // Geometry & Settings
  private animationId: number | null = null;
  private tileGeometry: THREE.PlaneGeometry;
  private oPieceGeometry: THREE.ExtrudeGeometry | null = null; // X generated dynamically
  private extrudeSettings = { depth: 0.15, bevelEnabled: true, bevelThickness: 0.02, bevelSize: 0.02, bevelSegments: 2 };

  // State
  private boardSize: number = 3;
  private targetCameraPosition = new THREE.Vector3();
  private targetLookAt = new THREE.Vector3();

  // Post-processing - Keep setup but OutlinePass will be removed later
  private composer: EffectComposer;
  private outlinePass: OutlinePass; // Will be replaced by BloomPass
  private objectsToOutline: THREE.Object3D[] = []; // Will be unused later

  // Background Shader
  private backgroundUniforms: { [uniform: string]: THREE.IUniform };

  constructor(assetLoader: AssetLoader) {
    this.assetLoader = assetLoader;

    // --- Basic Scene Setup ---
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    // Set output colorspace - important for consistency, even without bloom yet
    this.renderer.outputColorSpace = THREE.SRGBColorSpace;
    // ADDED: Tone Mapping for Bloom (Step 3)
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 1.0;
    this.renderer.domElement.style.position = 'fixed';
    this.renderer.domElement.style.top = '0';
    this.renderer.domElement.style.left = '0';
    this.renderer.domElement.style.zIndex = '1';
    this.renderer.domElement.style.pointerEvents = 'auto';
    document.body.appendChild(this.renderer.domElement);
    this.board = new THREE.Group();
    this.scene.add(this.board);
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true; this.controls.dampingFactor = 0.1;
    this.controls.enableZoom = true; this.controls.minDistance = 3; this.controls.maxDistance = 20;
    this.controls.enablePan = false; this.controls.target.set(0, 0, 0);
    // --- End Scene Setup ---

    // --- Geometries ---
    this.tileGeometry = new THREE.PlaneGeometry(1, 1);
    this.createPieceGeometries(); // Only creates O geometry
    // --- End Geometries ---

    // --- Background Shader (Ripple Gradient) ---
    this.backgroundUniforms = {
        u_time: { value: 0.0 },
        u_resolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) }
    };
    const backgroundGeometry = new THREE.PlaneGeometry(2, 2);
    const backgroundMaterial = new THREE.ShaderMaterial({
        uniforms: this.backgroundUniforms,
        vertexShader: `varying vec2 vUv; void main() { vUv = uv; gl_Position = vec4(position.xy, 1.0, 1.0); }`,
        // NEW Fragment Shader for Ripple Gradient
        fragmentShader: `
            uniform vec2 u_resolution;
            uniform float u_time;
            varying vec2 vUv;

            #define RIPPLE_SPEED 0.2
            #define RIPPLE_FREQ 5.0
            #define COLOR1 vec3(0.01, 0.02, 0.05) // Dark Blue/Purple
            #define COLOR2 vec3(0.0, 0.08, 0.06)  // Dark Teal/Green
            #define CENTER vec2(0.5, 0.5)

            void main() {
                vec2 st = vUv;
                float aspect = u_resolution.x / u_resolution.y;
                st.x *= aspect; // Correct for aspect ratio
                vec2 centerCorrected = CENTER;
                centerCorrected.x *= aspect;

                float dist = distance(st, centerCorrected);

                // Create a smooth wave based on distance and time
                float wave = sin(dist * RIPPLE_FREQ - u_time * RIPPLE_SPEED);

                // Normalize wave to 0.0 - 1.0
                float normalizedWave = (wave * 0.5 + 0.5);

                // Interpolate between two dark colors based on the wave
                vec3 color = mix(COLOR1, COLOR2, normalizedWave);

                // Optional: Darken edges further
                float edgeFade = smoothstep(0.8 * aspect, 0.4 * aspect, dist);
                color *= (1.0 - edgeFade * 0.5); // Gently darken edges

                gl_FragColor = vec4(color, 1.0);
            }
        `,
        depthWrite: false, depthTest: false
    });
    const backgroundMesh = new THREE.Mesh(backgroundGeometry, backgroundMaterial);
    backgroundMesh.renderOrder = -1; // Render behind everything
    this.scene.add(backgroundMesh);
    // --- End Background ---

    // --- Simplified Lighting (Step 1) ---
    this.scene.remove(...this.scene.children.filter(obj => obj.isLight)); // Remove previous lights
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6); // Keep some ambient light, maybe slightly brighter
    this.scene.add(ambientLight);
    // --- End Lighting ---

    // --- Initialize Post-Processing (Bloom instead of Outline - Step 3) ---
    this.composer = new EffectComposer(this.renderer);
    const renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(renderPass);

    // REMOVED Outline Pass Setup
    // this.outlinePass = new OutlinePass(...)
    // this.composer.addPass(this.outlinePass);

    // ADDED Unreal Bloom Pass (Step 3 & 5: Tuned)
    const bloomPass = new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        0.4, // strength (Reduced from 0.5)
        0.2, // radius (Kept same)
        0.1  // threshold (Increased from 0.0)
    );
    this.composer.addPass(bloomPass);

    const outputPass = new OutputPass(); // Use OutputPass with sRGBEncoding
    this.composer.addPass(outputPass);
    // --- End Post-Processing ---

    // --- Listeners & Start ---
    const audioListener = this.assetLoader.getAudioListener();
    if (audioListener) { this.camera.add(audioListener); }
    window.addEventListener('resize', this.onWindowResize.bind(this));
    this.animate();
    // --- End Listeners & Start ---
  }

  // Only creates O geometry
  private createPieceGeometries(): void {
      const oShape = new Shape();
      oShape.absarc(0, 0, 0.4, 0, Math.PI * 2, false);
      const hole = new THREE.Path(); hole.absarc(0, 0, 0.28, 0, Math.PI * 2, true);
      oShape.holes.push(hole);
      this.oPieceGeometry = new ExtrudeGeometry(oShape, this.extrudeSettings);
      this.oPieceGeometry.center();
      this.xPieceGeometry = null; // X generated dynamically
  }

  public createBoard(boardSize: number = 3): void {
    console.log(`Creating ${boardSize}x${boardSize} board (Steps 1 & 2)`);
    this.boardSize = boardSize;
    this.clearBoard(); // Includes clearing outline selection
    // REMOVED: this.objectsToOutline = []; (Step 3)

    const spacing = 0.10; // Tighter spacing
    const tileSize = 1;
    const totalBoardDim = boardSize * tileSize + (boardSize - 1) * spacing;
    const baseThickness = 0.1; // Thinner base

    // --- Create Board Base (Step 1) ---
    const baseGeometry = new THREE.BoxGeometry(totalBoardDim + spacing, totalBoardDim + spacing, baseThickness);
    // Use dark basic material
    const baseMaterial = new THREE.MeshBasicMaterial({ color: COLOR_BASE });
    this.boardBase = new THREE.Mesh(baseGeometry, baseMaterial);
    this.boardBase.position.z = -baseThickness / 2;
    this.board.add(this.boardBase);
    // REMOVED: Don't outline base for this style (Step 3)
    // this.objectsToOutline.push(this.boardBase);

    // --- Create Dark Transparent Tiles (Step 1) ---
    const tileRaise = 0.005; // Very close to base
    // Dark, transparent basic material
    const tileMaterial = new THREE.MeshBasicMaterial({
        color: COLOR_TILE,
        transparent: true,
        opacity: 0.5, // Increased opacity (was 0.15)
    });
    const startPos = -totalBoardDim / 2 + tileSize / 2;

    for (let i = 0; i < boardSize; i++) {
      for (let j = 0; j < boardSize; j++) {
        const tile = new THREE.Mesh(this.tileGeometry, tileMaterial.clone()) as BoardTile;
        tile.position.set( startPos + j * (tileSize + spacing), startPos + (boardSize - 1 - i) * (tileSize + spacing), tileRaise );
        tile.userData = { row: i, col: j, isBlocked: false };
        this.tileMeshes.push(tile);
        this.board.add(tile);
      }
    }
    // ADDED: Grid Lines (Step 2)
    this.createGridLines(boardSize, tileSize, spacing, totalBoardDim, tileRaise);

    // --- Board Rotation & Camera Positioning ---
    this.board.rotation.x = -Math.PI / 6; // 30 degree tilt
    this.targetLookAt.set(0, 0, 0);
    const distance = this.getBoardSizeDistance(boardSize);
    this.targetCameraPosition.set(0, distance * 0.4, distance); // Shallower angle
    this.camera.position.copy(this.targetCameraPosition);
    this.camera.lookAt(this.targetLookAt);
    this.controls.target.copy(this.targetLookAt);
    this.controls.update();
    // REMOVED: Update outline pass (Step 3)
    // this.outlinePass.selectedObjects = this.objectsToOutline;
    this.board.visible = true;
    // --- End Rotation & Camera ---
  }

  public updateCell(cellIndex: number, symbol: 'X' | 'O' | 'blocked' | null): void {
    if (cellIndex < 0 || cellIndex >= this.tileMeshes.length) { return; }
    const tile = this.tileMeshes[cellIndex];

    // --- Remove existing piece --- (Logic remains similar)
    const existingPiece = this.pieceMeshes.get(cellIndex);
    if (existingPiece) {
      this.board.remove(existingPiece);
      // REMOVED: Remove from outline selection (Step 3)
      // this.objectsToOutline = this.objectsToOutline.filter(...)
      // this.outlinePass.selectedObjects = this.objectsToOutline;
      // Dispose materials/geometries
      if (existingPiece instanceof THREE.Group) {
          existingPiece.traverse((c)=>{if(c instanceof THREE.Mesh){if(c.geometry)c.geometry.dispose(); if(c.material instanceof THREE.Material){if(Array.isArray(c.material))c.material.forEach(m=>m.dispose());else c.material.dispose();}}});
      } else if (existingPiece instanceof THREE.Mesh && existingPiece.material instanceof THREE.Material) {
          if (Array.isArray(existingPiece.material)) existingPiece.material.forEach(m => m.dispose()); else existingPiece.material.dispose();
      }
      this.pieceMeshes.delete(cellIndex);
    }
    // --- End Remove Piece ---

    // --- Reset tile appearance (Step 1) ---
    if (tile.material instanceof THREE.MeshBasicMaterial) { // Check for Basic
        const mat = Array.isArray(tile.material) ? tile.material[0] : tile.material;
        // Use new COLOR_TILE
        if (mat) { mat.color.setHex(COLOR_TILE); mat.opacity = 0.5; mat.transparent = true; mat.needsUpdate = true; }
    }
    tile.userData.isBlocked = false;
    // --- End Reset Tile ---

    // --- Add new element (Step 1 - Basic Material) ---
    if (symbol === 'X' || symbol === 'O') {
      let piece: THREE.Object3D;
      const pieceColor = symbol === 'X' ? COLOR_X : COLOR_O;
      // Use BasicMaterial
      const pieceMaterial = new THREE.MeshBasicMaterial({ color: pieceColor });

      if (symbol === 'O') {
          if (!this.oPieceGeometry) { console.error("O Geometry missing!"); return; }
          piece = new THREE.Mesh(this.oPieceGeometry, pieceMaterial);
      } else { // symbol === 'X'
          const xBarWidth = 0.7; const xBarThickness = 0.18;
          const xShape = new THREE.Shape();
          xShape.moveTo(-xBarWidth/2,-xBarThickness/2); xShape.lineTo(xBarWidth/2,-xBarThickness/2); xShape.lineTo(xBarWidth/2,xBarThickness/2); xShape.lineTo(-xBarWidth/2,xBarThickness/2); xShape.closePath();
          const xGeom = new THREE.ExtrudeGeometry(xShape, this.extrudeSettings); // Create fresh geom
          const mesh1 = new THREE.Mesh(xGeom, pieceMaterial); mesh1.rotation.z = Math.PI / 4;
          const mesh2 = new THREE.Mesh(xGeom.clone(), pieceMaterial); mesh2.rotation.z = -Math.PI / 4; // Clone for second bar
          piece = new THREE.Group(); piece.add(mesh1); piece.add(mesh2);
      }

      // Position piece
      piece.position.copy(tile.position);
      piece.position.z = tile.position.z + this.extrudeSettings.depth / 2 + 0.02; // Increased Z offset slightly (was 0.01)
      this.board.add(piece);
      this.pieceMeshes.set(cellIndex, piece);
      // REMOVED: Add to outline (Step 3)
      // xGroup.children.forEach(c => this.objectsToOutline.push(c));

    } else if (symbol === 'blocked') {
       // Update tile appearance for blocked state (Step 1 - Basic Material)
       if (tile.material instanceof THREE.MeshBasicMaterial) { // Check for Basic
           const mat = Array.isArray(tile.material) ? tile.material[0] : tile.material;
           // Make blocked tiles darker red, slightly less transparent
           if (mat) { mat.color.setHex(0x660000); mat.opacity = 0.4; mat.transparent = true; mat.needsUpdate = true; }
       }
       tile.userData.isBlocked = true;
    }
    // REMOVED: Update outline pass (Step 3)
    // this.outlinePass.selectedObjects = this.objectsToOutline;
  }

  public clearBoard(): void {
    this.tileMeshes.forEach(tile => {
        this.board.remove(tile);
        if (tile.material instanceof THREE.MeshBasicMaterial) {
             const mat = Array.isArray(tile.material) ? tile.material[0] : tile.material;
             mat?.dispose();
        }
    });
    this.tileMeshes = [];

    this.pieceMeshes.forEach(piece => {
        this.board.remove(piece);
        // Properly dispose of group/mesh materials & geometries
        if (piece instanceof THREE.Group) {
             piece.traverse((c)=>{if(c instanceof THREE.Mesh){if(c.geometry)c.geometry.dispose(); if(c.material instanceof THREE.Material){if(Array.isArray(c.material))c.material.forEach(m=>m.dispose());else c.material.dispose();}}});
        } else if (piece instanceof THREE.Mesh && piece.material instanceof THREE.Material) {
             if (Array.isArray(piece.material)) piece.material.forEach(m => m.dispose()); else piece.material.dispose();
        }
    });
    this.pieceMeshes.clear();

    if (this.boardBase) {
      this.board.remove(this.boardBase);
      this.boardBase.geometry?.dispose();
      if (this.boardBase.material instanceof THREE.Material) { this.boardBase.material.dispose(); }
      this.boardBase = null;
    }

    // ADDED: Clear Grid Lines (Step 2)
    if (this.gridLines) {
        this.board.remove(this.gridLines);
        this.gridLines.geometry.dispose();
        if (this.gridLines.material instanceof THREE.Material) {
            this.gridLines.material.dispose();
        }
        this.gridLines = null;
    }

    // REMOVED: Clear outline selection (Step 3)
    // this.objectsToOutline = [];
    // if (this.outlinePass) this.outlinePass.selectedObjects = [];
  }

  // --- Getters ---
  public getClickableTiles(): BoardTile[] { return this.tileMeshes; }
  public getCameraRaycaster(mp: THREE.Vector2): THREE.Raycaster { const r=new THREE.Raycaster(); r.setFromCamera(mp,this.camera); return r; }
  public getBoardSize(): number { return this.boardSize; }
  // --- End Getters ---

  // --- Winning Line Animation (Step 1 - Basic Materials) ---
  public animateWinningLine(symbol: 'X' | 'O', winningLine: WinningLine[]): void {
    if (!winningLine || winningLine.length === 0) return;
    const winColor = symbol === 'X' ? COLOR_X : COLOR_O;

    // Simply brighten the winning pieces/tiles (no outline needed - Step 3)
    winningLine.forEach(pos => {
      const index = pos.row * this.boardSize + pos.col;
      const piece = this.pieceMeshes.get(index);
      if (piece) {
        if (piece instanceof THREE.Mesh) {
            const applyColor = (mesh: THREE.Mesh) => { if(mesh.material instanceof THREE.MeshBasicMaterial){const m=Array.isArray(mesh.material)?mesh.material[0]:mesh.material; if(m)m.color.setHex(winColor);} };
            applyColor(piece);
        } else if (piece instanceof THREE.Group) {
            piece.children.forEach(c => { if (c instanceof THREE.Mesh) {
                const applyColor = (mesh: THREE.Mesh) => { if(mesh.material instanceof THREE.MeshBasicMaterial){const m=Array.isArray(mesh.material)?mesh.material[0]:mesh.material; if(m)m.color.setHex(winColor);} };
                applyColor(c);
            }});
        }
      }
      // Optional: could also slightly change tile color/opacity under winning piece
    });
  }

  private clearWinningLineVisuals(): void {
    this.pieceMeshes.forEach((piece, index) => {
      const tile = this.tileMeshes[index];
      const originalSymbol = tile.userData.isBlocked ? 'blocked' : (piece ? (piece.children?.length > 0 ? 'X' : 'O') : null); // Infer symbol
      const originalColor = originalSymbol === 'X' ? COLOR_X : COLOR_O;

      if (piece instanceof THREE.Mesh) {
          const resetColor = (mesh: THREE.Mesh, origHex: number) => { if(mesh.material instanceof THREE.MeshBasicMaterial){const m=Array.isArray(mesh.material)?mesh.material[0]:mesh.material; if(m)m.color.setHex(origHex);} };
          if (originalSymbol === 'O') resetColor(piece, originalColor); // Only reset O color
      } else if (piece instanceof THREE.Group) {
          piece.children.forEach(c => { if (c instanceof THREE.Mesh) {
              const resetColor = (mesh: THREE.Mesh, origHex: number) => { if(mesh.material instanceof THREE.MeshBasicMaterial){const m=Array.isArray(mesh.material)?mesh.material[0]:mesh.material; if(m)m.color.setHex(origHex);} };
              if (originalSymbol === 'X') resetColor(c, originalColor); // Only reset X color
          }});
      }
       // Reset tile appearance if needed
    });
  }
  // --- End Winning Line ---

  // --- Board Resize & Camera Distance ---
  public resizeBoard(size: number): void { this.createBoard(size); this.onWindowResize(); }
  private getBoardSizeDistance(boardSize: number): number {
    // Simple heuristic: increase distance for larger boards
    // Reduced values to zoom closer
    return 4 + boardSize * 1.2; // Was 6 + boardSize * 1.5
  }
  public setCameraDistance(dist: number, lookAt = new THREE.Vector3(0,0,0)): void { this.targetLookAt.copy(lookAt); this.targetCameraPosition.set(lookAt.x, lookAt.y+dist*0.4, lookAt.z+dist); }
  // --- End Resize & Camera ---

  // --- Window Resize Handler ---
  private onWindowResize(): void {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.composer.setSize(window.innerWidth, window.innerHeight); // Update composer size
    this.backgroundUniforms.u_resolution.value.set(window.innerWidth, window.innerHeight);

    // ADDED: Update Grid Line Resolution (Step 2)
    if (this.gridLines && this.gridLines.material instanceof LineMaterial) {
        this.gridLines.material.resolution.set(window.innerWidth, window.innerHeight);
    }
  }
  // --- End Window Resize ---

  // --- Animation Loop ---
  private animate(): void {
    this.animationId = requestAnimationFrame(this.animate.bind(this));
    // Camera movement
    this.camera.position.lerp(this.targetCameraPosition, 0.05);
    this.camera.lookAt(this.targetLookAt);
    this.controls.update();
    // Update background shader time
    if (this.backgroundUniforms) { this.backgroundUniforms.u_time.value += 0.016; }
    // Render via composer
    this.composer.render();
  }
  // --- End Animation Loop ---

  // --- Dispose ---
  public dispose(): void {
    console.log("Disposing BoardRenderer resources...");
    if(this.animationId) cancelAnimationFrame(this.animationId);
    window.removeEventListener('resize', this.onWindowResize.bind(this));
    // Dispose shared geometries
    if (this.oPieceGeometry) this.oPieceGeometry.dispose();
    if (this.tileGeometry) this.tileGeometry.dispose(); // Base plane geo
    this.clearBoard(); // Disposes dynamic X geos, tile geos, materials etc.
    // Dispose scene graph materials/textures that might be missed
    this.scene.traverse(obj=>{if(obj instanceof THREE.Mesh && obj.material){if(Array.isArray(obj.material)){obj.material.forEach(m=>{if(m.map)m.map.dispose(); m.dispose();});}else{if(obj.material.map)obj.material.map.dispose(); obj.material.dispose();}}});
    // Dispose renderer, controls
    if(this.renderer) this.renderer.dispose();
    if(this.controls) this.controls.dispose();
    // Remove canvas
    if (this.renderer?.domElement.parentNode) { this.renderer.domElement.parentNode.removeChild(this.renderer.domElement); }
    // Dispose of composer and passes (Step 3)
    this.composer.dispose();
  }
  // --- End Dispose ---

  // --- ADDED HELPER for Grid Lines (Step 2) ---
  private createGridLines(boardSize: number, tileSize: number, spacing: number, totalBoardDim: number, raise: number): void {
      const positions: number[] = [];
      const halfBoard = totalBoardDim / 2;
      const lineOffset = tileSize / 2 + spacing / 2; // Position lines between tiles
      const zPos = raise + 0.015; // Slightly more offset for grid lines (was 0.01)

      // Vertical lines
      for (let j = 0; j < boardSize - 1; j++) {
          const x = -halfBoard + lineOffset + j * (tileSize + spacing);
          positions.push(x, halfBoard + spacing/2, zPos); // Top point
          positions.push(x, -halfBoard - spacing/2, zPos); // Bottom point
      }
      // Horizontal lines
      for (let i = 0; i < boardSize - 1; i++) {
          const y = halfBoard - lineOffset - i * (tileSize + spacing);
          positions.push(-halfBoard - spacing/2, y, zPos); // Left point
          positions.push(halfBoard + spacing/2, y, zPos); // Right point
      }

      if (positions.length === 0) return; // No lines for 1x1 board

      const geometry = new LineSegmentsGeometry();
      geometry.setPositions(positions);

      const material = new LineMaterial({
          color: COLOR_GRID,
          linewidth: 0.008, // Increased thickness (was 0.005)
          resolution: new THREE.Vector2(window.innerWidth, window.innerHeight), // Set initial resolution
          dashed: false,
          alphaToCoverage: true, // Improves anti-aliasing on transparent background
      });

      this.gridLines = new LineSegments2(geometry, material);
      this.gridLines.computeLineDistances(); // Important for LineMaterial
      this.gridLines.scale.set(1, 1, 1); // Ensure scale is correct
      this.board.add(this.gridLines);
  }
  // --- End Helper ---

  // REMOVE hide/show methods if they existed and just rely on board.visible
  public hideBoard(): void { this.board.visible = false; }
  public showBoard(): void { this.board.visible = true; }
} 