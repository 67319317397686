import { Board } from './board';

export class AI {
  private symbol: string = 'O';
  private opponent: string = 'X';
  private maxDepth: number;
  private initialized: boolean = false;

  constructor(maxDepth: number = 3) {
    this.maxDepth = maxDepth;
    
    // Add a longer delay before allowing the AI to make moves
    // This prevents the AI from making a move right after initialization
    setTimeout(() => {
      this.initialized = true;
      console.log("AI initialization complete - ready for moves");
    }, 100); // Reduced from 500ms to 100ms
  }

  public getMove(board: (string | null)[][]): { x: number; y: number } {
    // Don't make a move if not fully initialized
    if (!this.initialized) {
      console.log("AI not yet initialized, preventing premature move");
      return { x: 0, y: 0 }; // Return dummy move that won't be used
    }
    
    // Check if it's the first move of the game (mostly empty board)
    const cellCount = this.countNonEmptyCells(board);
    if (cellCount <= 1) {
      console.log("First AI move - ensuring player went first");
      // Make sure there's at least one X on the board already
      let playerMoved = false;
      for (let i = 0; i < board.length; i++) {
        for (let j = 0; j < board[i].length; j++) {
          if (board[i][j] === this.opponent) {
            playerMoved = true;
            break;
          }
        }
        if (playerMoved) break;
      }
      
      if (!playerMoved) {
        console.log("Player has not moved yet, preventing AI from going first");
        return { x: 0, y: 0 }; // Return dummy move
      }
    }
    
    // First check if we can win
    const winningMove = this.findWinningMove(board, this.symbol);
    if (winningMove) {
      console.log("AI found winning move:", winningMove);
      return winningMove;
    }
    
    // Then check if we need to block opponent's win
    const blockingMove = this.findWinningMove(board, this.opponent);
    if (blockingMove) {
      console.log("AI found blocking move:", blockingMove);
      return blockingMove;
    }
    
    let bestScore = -Infinity;
    let bestMove = { x: 0, y: 0 };

    // Try each available move
    for (let i = 0; i < board.length; i++) {
      for (let j = 0; j < board[i].length; j++) {
        if (board[i][j] === null) {
          // Make the move
          board[i][j] = this.symbol;
          
          // Get score for this move
          const score = this.minimax(board, 0, false);
          
          // Undo the move
          board[i][j] = null;

          // Update best move if this score is better
          if (score > bestScore) {
            bestScore = score;
            bestMove = { x: i, y: j };
          }
        }
      }
    }

    return bestMove;
  }

  // Find an immediate winning move for the given player
  private findWinningMove(board: (string | null)[][], player: string): { x: number; y: number } | null {
    const boardSize = board.length;
    const winLength = 3; // Win is 3 in a row, even on expanded board
    
    // Try each empty cell
    for (let i = 0; i < boardSize; i++) {
      for (let j = 0; j < boardSize; j++) {
        if (board[i][j] === null) {
          // Make the move
          board[i][j] = player;
          
          // Check if this makes a win
          const isWin = this.checkWinAt(board, player, i, j);
          
          // Undo the move
          board[i][j] = null;
          
          if (isWin) {
            return { x: i, y: j };
          }
        }
      }
    }
    
    return null;
  }

  private minimax(board: (string | null)[][], depth: number, isMaximizing: boolean): number {
    // Check terminal states
    if (this.checkWin(board, this.symbol)) return 10 - depth;
    if (this.checkWin(board, this.opponent)) return depth - 10;
    if (this.isBoardFull(board)) return 0;
    if (depth >= this.maxDepth) return 0;

    if (isMaximizing) {
      let bestScore = -Infinity;
      for (let i = 0; i < board.length; i++) {
        for (let j = 0; j < board[i].length; j++) {
          if (board[i][j] === null) {
            board[i][j] = this.symbol;
            bestScore = Math.max(bestScore, this.minimax(board, depth + 1, false));
            board[i][j] = null;
          }
        }
      }
      return bestScore;
    } else {
      let bestScore = Infinity;
      for (let i = 0; i < board.length; i++) {
        for (let j = 0; j < board[i].length; j++) {
          if (board[i][j] === null) {
            board[i][j] = this.opponent;
            bestScore = Math.min(bestScore, this.minimax(board, depth + 1, true));
            board[i][j] = null;
          }
        }
      }
      return bestScore;
    }
  }

  // Check win for the entire board
  private checkWin(board: (string | null)[][], player: string): boolean {
    const boardSize = board.length;
    const winLength = 3; // Win is 3 in a row, even on expanded board
    
    // Check all possible win positions
    for (let i = 0; i < boardSize; i++) {
      for (let j = 0; j < boardSize; j++) {
        if (board[i][j] === player && this.checkWinAt(board, player, i, j)) {
          return true;
        }
      }
    }
    
    return false;
  }
  
  // Check if there's a win starting from a specific position
  private checkWinAt(board: (string | null)[][], player: string, row: number, col: number): boolean {
    const boardSize = board.length;
    const winLength = 3; // Win is 3 in a row, even on expanded board
    
    // Check horizontal
    if (col <= boardSize - winLength) {
      let match = true;
      for (let k = 0; k < winLength; k++) {
        if (board[row][col + k] !== player) {
          match = false;
          break;
        }
      }
      if (match) return true;
    }
    
    // Check vertical
    if (row <= boardSize - winLength) {
      let match = true;
      for (let k = 0; k < winLength; k++) {
        if (board[row + k][col] !== player) {
          match = false;
          break;
        }
      }
      if (match) return true;
    }
    
    // Check diagonal (top-left to bottom-right)
    if (row <= boardSize - winLength && col <= boardSize - winLength) {
      let match = true;
      for (let k = 0; k < winLength; k++) {
        if (board[row + k][col + k] !== player) {
          match = false;
          break;
        }
      }
      if (match) return true;
    }
    
    // Check diagonal (top-right to bottom-left)
    if (row <= boardSize - winLength && col >= winLength - 1) {
      let match = true;
      for (let k = 0; k < winLength; k++) {
        if (board[row + k][col - k] !== player) {
          match = false;
          break;
        }
      }
      if (match) return true;
    }
    
    return false;
  }

  private isBoardFull(board: (string | null)[][]): boolean {
    return board.every(row => row.every(cell => cell !== null));
  }

  // Count the number of non-empty cells in the board
  private countNonEmptyCells(board: (string | null)[][]): number {
    let count = 0;
    for (let i = 0; i < board.length; i++) {
      for (let j = 0; j < board[i].length; j++) {
        if (board[i][j] !== null) {
          count++;
        }
      }
    }
    return count;
  }
} 