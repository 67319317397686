import { authService } from '../services/authService';

export interface WebSocketMessage {
  type: string;
  [key: string]: any;
}

export interface WebSocketOptions {
  url: string;
  token?: string;
  onOpen?: () => void;
  onClose?: (event: CloseEvent) => void;
  onError?: (error: Event) => void;
  onMessage?: (data: any) => void;
  pingInterval?: number;
}

export class WebSocketManager {
  private connection: WebSocket | null = null;
  private url: string;
  private token: string | null = null;
  private pingIntervalId: number | null = null;
  private lastPongTime: number = Date.now();
  private messageHandlers: Map<string, (data: any) => void> = new Map();
  private isConnecting: boolean = false;
  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 3;
  private options: WebSocketOptions;
  private pongTimeoutId: number | null = null;

  constructor(options: WebSocketOptions) {
    this.url = options.url;
    this.token = options.token || authService.getToken() || null;
    this.options = options;
  }

  public connect(): Promise<void> {
    if (this.connection && this.connection.readyState === WebSocket.OPEN) {
      console.log('WebSocket already connected');
      return Promise.resolve();
    }

    if (this.isConnecting) {
      console.log('WebSocket connection already in progress');
      return Promise.resolve();
    }

    this.isConnecting = true;
    
    return new Promise((resolve, reject) => {
      try {
        // Build URL with token if available
        let wsUrl = this.url;
        if (this.token) {
          wsUrl += (wsUrl.includes('?') ? '&' : '?') + `token=${this.token}`;
        }
        
        console.log(`Connecting to WebSocket: ${wsUrl}`);
        this.connection = new WebSocket(wsUrl);

        // Set up timeout for connection
        const connectionTimeout = setTimeout(() => {
          if (this.connection && this.connection.readyState === WebSocket.CONNECTING) {
            console.warn("WebSocket connection timed out");
            this.connection.close();
            this.isConnecting = false;
            reject(new Error("Connection timeout"));
          }
        }, 7000);

        this.connection.onopen = () => {
          console.log('WebSocket connection established');
          clearTimeout(connectionTimeout);
          this.isConnecting = false;
          this.reconnectAttempts = 0;
          
          // Set up ping-pong heartbeat if interval is specified
          if (this.options.pingInterval) {
            this.setupHeartbeat(this.options.pingInterval);
          }
          
          // Call the onOpen callback if provided
          if (this.options.onOpen) {
            this.options.onOpen();
          }
          
          resolve();
        };

        this.connection.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            console.log('WebSocket message received:', data);
            
            // Handle pings automatically
            if (data.type === 'ping') {
              this.send({
                type: 'pong',
                timestamp: Date.now()
              });
              return;
            }
            
            // Update last pong time for heartbeat monitoring
            if (data.type === 'pong') {
              this.lastPongTime = Date.now();
              return;
            }
            
            // Call specific handler for this message type if it exists
            const handler = this.messageHandlers.get(data.type);
            if (handler) {
              handler(data);
            }
            
            // Call the general onMessage callback if provided
            if (this.options.onMessage) {
              this.options.onMessage(data);
            }
          } catch (error) {
            console.error('Error processing WebSocket message:', error);
          }
        };

        this.connection.onclose = (event) => {
          console.log(`WebSocket connection closed: code=${event.code}, reason=${event.reason}`);
          this.clearHeartbeat();
          this.isConnecting = false;
          
          // Attempt to reconnect if not a normal closure
          if (event.code !== 1000 && this.reconnectAttempts < this.maxReconnectAttempts) {
            this.attemptReconnect();
          }
          
          // Call the onClose callback if provided
          if (this.options.onClose) {
            this.options.onClose(event);
          }
          
          // If we're still connecting, reject the promise
          if (this.isConnecting) {
            clearTimeout(connectionTimeout);
            this.isConnecting = false;
            reject(new Error(`Connection closed: ${event.code}`));
          }
        };

        this.connection.onerror = (error) => {
          console.error('WebSocket error:', error);
          this.isConnecting = false;
          
          // Call the onError callback if provided
          if (this.options.onError) {
            this.options.onError(error);
          }
          
          reject(error);
        };
      } catch (error) {
        console.error('Error setting up WebSocket:', error);
        this.isConnecting = false;
        reject(error);
      }
    });
  }

  public send(message: WebSocketMessage): boolean {
    if (!this.connection || this.connection.readyState !== WebSocket.OPEN) {
      console.error('Cannot send message: WebSocket not connected');
      return false;
    }

    try {
      this.connection.send(JSON.stringify(message));
      return true;
    } catch (error) {
      console.error('Error sending WebSocket message:', error);
      return false;
    }
  }

  public disconnect(notificationType?: string, additionalData?: Record<string, any>): void {
    console.log('Disconnecting WebSocket');
    
    this.clearHeartbeat();
    this.reconnectAttempts = this.maxReconnectAttempts;
    
    if (this.connection) {
      try {
        // Send a disconnect notification if the connection is open and a notification type is provided
        if (this.connection.readyState === WebSocket.OPEN && notificationType) {
          const message = {
            type: notificationType,
            timestamp: Date.now(),
            ...additionalData
          };
          
          // Attempt to send the message, but don't wait
          try {
            this.connection.send(JSON.stringify(message));
            console.info(`Sent disconnect notification: ${notificationType}`);
          } catch (sendError) {
            console.error(`Failed to send disconnect notification: ${sendError}`);
          }
        }
        
        // Close the connection immediately, remove the setTimeout
        this.connection.close();
        this.connection = null;
        console.info('WebSocket connection closed immediately.');
        
      } catch (error) {
        console.error('Error during WebSocket disconnection:', error);
        // Ensure connection is nullified even if close fails
        this.connection = null;
      }
    } else {
      console.info('WebSocket already disconnected or null.');
    }
    // Ensure connecting flag is reset
    this.isConnecting = false;
  }

  public registerMessageHandler(type: string, handler: (data: any) => void): void {
    this.messageHandlers.set(type, handler);
  }

  public removeMessageHandler(type: string): void {
    this.messageHandlers.delete(type);
  }

  public isConnected(): boolean {
    return !!this.connection && this.connection.readyState === WebSocket.OPEN;
  }

  private setupHeartbeat(interval: number): void {
    this.clearHeartbeat();
    
    this.pingIntervalId = setInterval(() => {
      if (this.connection && this.connection.readyState === WebSocket.OPEN) {
        this.send({ type: 'ping', timestamp: Date.now() });
        
        // Schedule a check to see if pong was received
        this.pongTimeoutId = setTimeout(() => {
          if (Date.now() - this.lastPongTime > interval + 10000) {
            console.warn('Pong not received in time. Closing connection.');
            this.connection?.close();
          }
        }, interval + 5000); // Schedule check after expected pong arrival
      }
    }, interval);
  }

  private clearHeartbeat(): void {
    if (this.pingIntervalId !== null) {
      clearInterval(this.pingIntervalId);
      this.pingIntervalId = null;
    }
    if (this.pongTimeoutId !== null) {
      clearTimeout(this.pongTimeoutId);
      this.pongTimeoutId = null;
    }
  }

  private attemptReconnect(): void {
    this.reconnectAttempts++;
    
    const delay = Math.min(1000 * Math.pow(2, this.reconnectAttempts), 5000);
    console.log(`Attempting to reconnect in ${delay}ms (attempt ${this.reconnectAttempts}/${this.maxReconnectAttempts})`);
    
    setTimeout(() => {
      this.connect().catch(error => {
        console.error('Reconnection attempt failed:', error);
      });
    }, delay);
  }
} 