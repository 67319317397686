// Authentication service for TicTacTitans

interface User {
  username: string;
  user_id: string;
  email?: string;
}

class AuthService {
  private static readonly TOKEN_KEY = 'ttt_token';
  private static readonly USER_KEY = 'ttt_user';
  private static readonly API_BASE_URL = 'https://api.anklebite.games';
  
  private user: User | null = null;
  private token: string | null = null;
  
  constructor() {
    // Load user and token from localStorage on init
    this.loadFromStorage();
  }
  
  private loadFromStorage(): void {
    try {
      // Load token
      const storedToken = localStorage.getItem(AuthService.TOKEN_KEY);
      if (storedToken) {
        this.token = storedToken;
      }
      
      // Load user
      const storedUser = localStorage.getItem(AuthService.USER_KEY);
      if (storedUser) {
        this.user = JSON.parse(storedUser);
      }
    } catch (error) {
      console.error('Error loading auth data from storage:', error);
      // Clear potentially corrupted data
      this.clearStorage();
    }
  }
  
  private saveToStorage(): void {
    try {
      // Save token
      if (this.token) {
        localStorage.setItem(AuthService.TOKEN_KEY, this.token);
      } else {
        localStorage.removeItem(AuthService.TOKEN_KEY);
      }
      
      // Save user
      if (this.user) {
        localStorage.setItem(AuthService.USER_KEY, JSON.stringify(this.user));
      } else {
        localStorage.removeItem(AuthService.USER_KEY);
      }
    } catch (error) {
      console.error('Error saving auth data to storage:', error);
    }
  }
  
  private clearStorage(): void {
    localStorage.removeItem(AuthService.TOKEN_KEY);
    localStorage.removeItem(AuthService.USER_KEY);
  }
  
  public isLoggedIn(): boolean {
    return Boolean(this.token && this.user);
  }
  
  public getUser(): User | null {
    return this.user;
  }
  
  public getToken(): string | null {
    return this.token;
  }
  
  public async checkAuth(): Promise<boolean> {
    // Check if we have a token and user stored
    if (this.token && this.user) {
      try {
        // Verify the token with the server (optional)
        const response = await fetch(`${AuthService.API_BASE_URL}/auth/verify`, {
          method: 'GET',
          headers: this.getAuthHeaders(),
        });
        
        // If the token is valid, return true
        return response.ok;
      } catch (error) {
        console.error('Error verifying token:', error);
        // If there's an error, we'll consider the user not logged in
        this.logout();
        return false;
      }
    }
    
    return false;
  }
  
  public async login(username: string, password: string): Promise<User> {
    try {
      // Call the backend API to authenticate
      const response = await fetch(`${AuthService.API_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Login failed');
      }
      
      const data = await response.json();
      
      // Store the user and token
      this.user = {
        username: data.username,
        user_id: data.user_id,
      };
      
      this.token = data.access_token;
      
      // Save to storage
      this.saveToStorage();
      
      return this.user;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }
  
  public async register(username: string, password: string, email?: string): Promise<User> {
    try {
      // Call the backend API to register
      const response = await fetch(`${AuthService.API_BASE_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password, email }),
      });
      
      if (!response.ok) {
        // Handle non-OK responses
        console.error('Registration failed with status:', response.status);
        let errorDetails = 'Unknown error';
        try {
          // Attempt to parse backend error details
          const errorData = await response.json(); 
          errorDetails = errorData.detail || JSON.stringify(errorData); 
        } catch (parseError) {
          // If parsing fails, use the response text
          try {
              errorDetails = await response.text();
          } catch (textError) {
              errorDetails = `Status ${response.status} - Unable to parse error response.`;
          }
        }
        // Throw an error with more details
        throw new Error(errorDetails);
      }
      
      const data = await response.json();
      
      // Store the user and token
      this.user = {
        username: data.username,
        user_id: data.user_id,
        email: data.email,
      };
      this.token = data.access_token;
      
      // Save to storage
      this.saveToStorage();
      
      return this.user;
    } catch (error: any) { // Catch any error (network, parsing, etc.)
      // Log the specific error caught
      console.error('Registration error:', error);
      // Re-throw the original error or a new one with the message
      throw new Error(error.message || 'Registration failed due to an unexpected error.');
    }
  }
  
  public logout(): void {
    // Clear user and token
    this.user = null;
    this.token = null;
    
    // Clear storage
    this.clearStorage();
  }
  
  // Helper method to get auth headers for API requests
  public getAuthHeaders(): HeadersInit {
    return this.token ? {
      'Authorization': `Bearer ${this.token}`,
      'Content-Type': 'application/json'
    } : {
      'Content-Type': 'application/json'
    };
  }
}

// Export a singleton instance
export const authService = new AuthService(); 