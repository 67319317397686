import * as THREE from 'three';

interface AssetManifest {
  version: string;
  audio: {
    music: AudioAsset[];
    sfx: AudioAsset[];
  };
  sprites: {
    symbols: SpriteAsset[];
    particles: ParticleAsset[];
  };
  shaders: {
    [key: string]: ShaderAsset;
  };
  textures?: {
    tiles?: SpriteAsset[];
    symbols?: SpriteAsset[];
  };
}

interface AudioAsset {
  id: string;
  file: string;
  source?: string;
  loop?: boolean;
  volume?: number;
  description?: string;
}

interface SpriteAsset {
  id: string;
  file: string;
  animations?: {
    [key: string]: {
      frames: number;
      duration: number;
      loop?: boolean;
    };
  };
}

interface ParticleAsset {
  id: string;
  file: string;
  frames: number;
  frameSize: {
    width: number;
    height: number;
  };
}

interface ShaderAsset {
  vertex: string;
  fragment: string;
}

export class AssetLoader {
  private textures: Map<string, THREE.Texture> = new Map();
  private audioBuffers: Map<string, AudioBuffer> = new Map();
  private audioContext: AudioContext | null = null;
  private loadingPromises: Map<string, Promise<any>> = new Map();
  private maxRetries: number = 3;
  private backgroundMusic: THREE.Audio | null = null;
  private soundEffects: Map<string, THREE.Audio> = new Map();
  private audioListener: THREE.AudioListener | null = null;

  constructor() {
    // Initialize maps
    this.textures = new Map();
    this.audioBuffers = new Map();
    this.loadingPromises = new Map();
    this.soundEffects = new Map();
    
    // Add event listener for resuming audio on mobile
    document.addEventListener('resumeAudio', this.handleResumeAudio.bind(this));
  }

  private handleResumeAudio(): void {
    console.log('AssetLoader: Handling resumeAudio event');
    // Resume AudioContext if it exists and is suspended
    if (this.audioContext && this.audioContext.state === 'suspended') {
      console.log('Resuming AudioContext from event handler');
      this.audioContext.resume().then(() => {
        console.log('AudioContext resumed successfully');
        // Try to play background music if it exists but isn't playing
        if (this.backgroundMusic && !this.backgroundMusic.isPlaying) {
          console.log('Attempting to play background music after resume');
          this.backgroundMusic.play();
        }
      }).catch(err => {
        console.error('Error resuming AudioContext from event:', err);
      });
    }
  }

  public async init(): Promise<void> {
    try {
      console.log('Initializing asset loader...');
      
      // Create fallback textures first
      this.createFallbackTextures();
      console.log('Fallback textures created');

      // Try loading actual textures
      try {
        await Promise.all([
          this.loadTexture('cell_empty', '/assets/tiles/tile1.svg'),
          this.loadTexture('cell_x', '/assets/symbols/x.svg'),
          this.loadTexture('cell_o', '/assets/symbols/o.svg'),
          this.loadTexture('wood_base', '/assets/textures/wood_base.jpg')
        ]);
        console.log('Successfully loaded all textures');
      } catch (error) {
        console.warn('Failed to load actual textures, using fallbacks:', error);
      }

      // Initialize audio with delay (helps with mobile browser issues)
      setTimeout(() => this.initAudio(), 100);
      console.log('Audio initialization scheduled');

      console.log('Asset loader initialized successfully');
    } catch (error) {
      console.error('Failed to initialize asset loader:', error);
      // Don't throw error, we have fallbacks
      console.log('Using fallback textures');
    }
  }

  private async initAudio(): Promise<void> {
    try {
      // Create audio context
      this.initAudioContext();
      
      // Create audio listener
      this.audioListener = new THREE.AudioListener();
      
      // Create fallback audio
      this.createFallbackAudio();
      
      // Try loading actual audio files
      try {
        await Promise.all([
          this.loadAudio('track1', '/assets/music/1.mp3', true),
          this.loadAudio('place', '/assets/sfx/place.mp3', false),
          this.loadAudio('win', '/assets/sfx/win.mp3', false),
          this.loadAudio('lose', '/assets/sfx/lose.mp3', false)
        ]);
      } catch (error) {
        console.warn('Failed to load audio files, using fallbacks:', error);
      }
      
      console.log('Audio initialization complete');
    } catch (error) {
      console.warn('Failed to initialize audio:', error);
    }
  }

  private initAudioContext(): void {
    if (!this.audioContext) {
      try {
        this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        console.log('AudioContext created with state:', this.audioContext.state);
        
        // Attempt to resume immediately if suspended
        if (this.audioContext.state === 'suspended') {
          console.log('New AudioContext is suspended, attempting to resume...');
          this.audioContext.resume().then(() => {
            console.log('New AudioContext resumed successfully');
          }).catch(err => {
            console.warn('Could not resume new AudioContext:', err);
          });
        }
      } catch (error) {
        console.error('Failed to create AudioContext:', error);
        this.audioContext = null;
      }
    }
  }

  private createFallbackAudio(): void {
    if (!this.audioContext || !this.audioListener) return;

    // Create oscillator-based sound effects
    const createOscillatorBuffer = (frequency: number, duration: number): AudioBuffer => {
      const sampleRate = this.audioContext!.sampleRate;
      const buffer = this.audioContext!.createBuffer(1, sampleRate * duration, sampleRate);
      const data = buffer.getChannelData(0);
      
      for (let i = 0; i < buffer.length; i++) {
        const t = i / sampleRate;
        data[i] = Math.sin(2 * Math.PI * frequency * t) * 
                  Math.exp(-5 * t); // Add decay
      }
      
      return buffer;
    };

    // Create place sound (higher pitch)
    const placeBuffer = createOscillatorBuffer(440, 0.1); // A4 note
    const placeSound = new THREE.Audio(this.audioListener);
    placeSound.setBuffer(placeBuffer);
    placeSound.setVolume(0.3);
    this.soundEffects.set('place', placeSound);
    this.audioBuffers.set('place', placeBuffer);

    // Create win sound (ascending notes)
    const winBuffer = createOscillatorBuffer(523.25, 0.3); // C5 note
    const winSound = new THREE.Audio(this.audioListener);
    winSound.setBuffer(winBuffer);
    winSound.setVolume(0.3);
    this.soundEffects.set('win', winSound);
    this.audioBuffers.set('win', winBuffer);
    
    // Create lose sound (descending notes)
    const loseBuffer = createOscillatorBuffer(349.23, 0.5); // F4 note with longer duration
    const loseSound = new THREE.Audio(this.audioListener);
    loseSound.setBuffer(loseBuffer);
    loseSound.setVolume(0.3);
    this.soundEffects.set('lose', loseSound);
    this.audioBuffers.set('lose', loseBuffer);

    // Create background music (low frequency drone)
    const musicBuffer = createOscillatorBuffer(110, 2.0); // A2 note
    const music = new THREE.Audio(this.audioListener);
    music.setBuffer(musicBuffer);
    music.setLoop(true);
    music.setVolume(0.2);
    this.backgroundMusic = music;
    this.audioBuffers.set('track1', musicBuffer);
  }

  private async loadAudio(id: string, path: string, isMusic: boolean): Promise<void> {
    try {
      console.log(`Attempting to load audio ${id} from: ${path}`);
      const response = await fetch(path);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      // Log the content type to help debug MIME type issues
      const contentType = response.headers.get('content-type');
      console.log(`Content-Type for ${id}: ${contentType}`);
      
      const arrayBuffer = await response.arrayBuffer();
      console.log(`Received ${arrayBuffer.byteLength} bytes for ${id}`);
      
      if (arrayBuffer.byteLength === 0) {
        throw new Error('Received empty audio file');
      }

      try {
        const audioBuffer = await this.audioContext!.decodeAudioData(arrayBuffer);
        console.log(`Successfully decoded audio data for ${id}`);
        
        if (isMusic) {
          // Create background music
          const music = new THREE.Audio(this.audioListener!);
          music.setBuffer(audioBuffer);
          music.setLoop(true);
          music.setVolume(0.5);
          if (id === 'track1') {
            this.backgroundMusic = music;
          }
        } else {
          // Create sound effect
          const sound = new THREE.Audio(this.audioListener!);
          sound.setBuffer(audioBuffer);
          sound.setLoop(false);
          sound.setVolume(0.7);
          this.soundEffects.set(id, sound);
        }
        
        this.audioBuffers.set(id, audioBuffer);
      } catch (decodeError) {
        console.error(`Failed to decode audio data for ${id}:`, decodeError);
        throw decodeError;
      }
    } catch (error) {
      console.warn(`Failed to load audio ${id}, using fallback:`, error);
      // Create fallback audio if we haven't already
      if (!this.audioBuffers.has(id)) {
        this.createFallbackAudio();
      }
    }
  }

  public playBackgroundMusic(): void {
    console.log('Attempting to play background music...');
    
    // Check if audio context exists
    if (!this.audioContext) {
        console.log('Creating AudioContext first...');
        this.initAudioContext();
    }
    
    // Check if audio context is in suspended state (common in browsers that require user interaction)
    if (this.audioContext && this.audioContext.state === 'suspended') {
        console.log('AudioContext is suspended, attempting to resume');
        this.audioContext.resume().then(() => {
            console.log('AudioContext resumed, now playing music');
            this.startBackgroundMusic();
        }).catch(err => {
            console.error('Failed to resume AudioContext:', err);
            // Try playing anyway
            this.startBackgroundMusic();
        });
    } else {
        // Either audioContext is running or doesn't exist
        this.startBackgroundMusic();
    }
  }

  private startBackgroundMusic(): void {
    if (!this.backgroundMusic) {
        console.log('No background music available, creating from audio buffer');
        
        // Try to create background music from buffer if available
        const musicBuffer = this.audioBuffers.get('track1');
        if (musicBuffer && this.audioListener) {
            this.backgroundMusic = new THREE.Audio(this.audioListener);
            this.backgroundMusic.setBuffer(musicBuffer);
            this.backgroundMusic.setLoop(true);
            this.backgroundMusic.setVolume(0.3);
        } else {
            console.error('Cannot create background music: missing buffer or listener');
            return;
        }
    }
    
    // Check if background music exists but isn't playing
    if (this.backgroundMusic && !this.backgroundMusic.isPlaying) {
        try {
            console.log('Starting background music playback');
            this.backgroundMusic.play();
            
            // Force user to hear the beautiful music!
            // Add a button to the UI to unmute if they really don't want to hear it
            if (this.backgroundMusic.source && this.backgroundMusic.source.gainNode) {
                this.backgroundMusic.source.gainNode.gain.value = 0.3;
            }
            
            console.log('Background music playback started');
        } catch (err) {
            console.error('Error playing background music:', err);
        }
    } else if (this.backgroundMusic && this.backgroundMusic.isPlaying) {
        console.log('Background music is already playing');
    } else {
        console.error('Failed to play background music: no music object available');
    }
  }

  public stopBackgroundMusic(): void {
    if (this.backgroundMusic && this.backgroundMusic.isPlaying) {
      this.backgroundMusic.stop();
    }
  }

  public playSoundEffect(id: string): void {
    const sound = this.soundEffects.get(id);
    if (sound) {
      if (sound.isPlaying) {
        sound.stop();
      }
      sound.play();
    }
  }

  private createFallbackTextures(): void {
    // Create fallback textures
    const createFallbackTexture = (color: string): THREE.Texture => {
      const canvas = document.createElement('canvas');
      canvas.width = 64;
      canvas.height = 64;
      const ctx = canvas.getContext('2d')!;
      
      // Fill background
      ctx.fillStyle = '#1a1a2e';
      ctx.fillRect(0, 0, 64, 64);
      
      // Draw border
      ctx.strokeStyle = color;
      ctx.lineWidth = 2;
      ctx.strokeRect(2, 2, 60, 60);
      
      // For X and O, draw the symbol
      if (color === '#ff3366') { // X
        ctx.strokeStyle = color;
        ctx.lineWidth = 4;
        ctx.beginPath();
        ctx.moveTo(16, 16);
        ctx.lineTo(48, 48);
        ctx.moveTo(48, 16);
        ctx.lineTo(16, 48);
        ctx.stroke();
      } else if (color === '#33ccff') { // O
        ctx.strokeStyle = color;
        ctx.lineWidth = 4;
        ctx.beginPath();
        ctx.arc(32, 32, 16, 0, Math.PI * 2);
        ctx.stroke();
      }
      
      const texture = new THREE.CanvasTexture(canvas);
      texture.minFilter = THREE.NearestFilter;
      texture.magFilter = THREE.NearestFilter;
      return texture;
    };

    // Create and store fallback textures
    this.textures.set('cell_empty', createFallbackTexture('#33ff66'));
    this.textures.set('cell_x', createFallbackTexture('#ff3366'));
    this.textures.set('cell_o', createFallbackTexture('#33ccff'));
  }

  private async loadTexture(id: string, path: string, retries: number = 0): Promise<THREE.Texture> {
    if (this.textures.has(id)) {
      return this.textures.get(id)!;
    }

    if (this.loadingPromises.has(id)) {
      return this.loadingPromises.get(id)!;
    }

    const loadPromise = new Promise<THREE.Texture>((resolve, reject) => {
      console.log(`Loading texture ${id} from: ${path}`);
      const loader = new THREE.TextureLoader();
      
      loader.load(
        path,
        (texture) => {
          texture.minFilter = THREE.NearestFilter;
          texture.magFilter = THREE.NearestFilter;
          texture.generateMipmaps = false;
          texture.needsUpdate = true;
          
          this.textures.set(id, texture);
          this.loadingPromises.delete(id);
          console.log(`Successfully loaded texture ${id} from: ${path}`);
          resolve(texture);
        },
        undefined,
        async (error) => {
          console.warn(`Failed to load texture ${id} from ${path}:`, error);
          if (retries < this.maxRetries) {
            console.log(`Retrying texture ${id} (attempt ${retries + 1}/${this.maxRetries})`);
            try {
              const texture = await this.loadTexture(id, path, retries + 1);
              resolve(texture);
            } catch (retryError) {
              reject(retryError);
            }
          } else {
            // Use fallback texture
            console.log(`Using fallback texture for ${id}`);
            resolve(this.textures.get(id)!);
          }
        }
      );
    });

    this.loadingPromises.set(id, loadPromise);
    return loadPromise;
  }

  public getTexture(id: string): THREE.Texture {
    const texture = this.textures.get(id);
    if (!texture) {
      console.warn(`Texture ${id} not found, using fallback`);
      return this.textures.get('cell_empty')!;
    }
    return texture;
  }

  public getAudioBuffer(id: string): AudioBuffer | null {
    return this.audioBuffers.get(id) || null;
  }

  public getAudioListener(): THREE.AudioListener | null {
    return this.audioListener;
  }

  public dispose(): void {
    // Stop all audio
    this.stopBackgroundMusic();
    this.soundEffects.forEach(sound => {
      if (sound.isPlaying) {
        sound.stop();
      }
    });

    // Dispose textures
    this.textures.forEach(texture => {
      texture.dispose();
    });

    // Clear maps
    this.textures.clear();
    this.audioBuffers.clear();
    this.soundEffects.clear();
    this.loadingPromises.clear();

    // Close audio context
    if (this.audioContext && this.audioContext.state !== 'closed') {
      this.audioContext.close();
    }
  }
} 